import React, { FunctionComponent, ReactElement, ReactNode, Ref } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import {
  CrossIcon,
  LoaderIcon,
  SearchNewBoIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { MessageType } from '@savgroup-front-common/types';

import messages from './messages';
import { useSearchInput } from './SearchInput.hooks';
import {
  $SearchActionContainer,
  $SearchClearButton,
  $SearchFilter,
  $SearchIconContainer,
  $SearchInput,
  $SearchInputContainer,
  $SearchLoadingContainer,
} from './SearchInput.styles';

interface SearchInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
  placeholder?: MessageType | string;
  isLoading?: boolean;
  isActive?: boolean;
  dataTestId?: string;
  children?: ReactNode;
  searchInputDefaultValue?: string;
  componentThemeName?: string;
  variant?: string;
  searchFilter?: ReactElement;
}
interface SearchInputWithRefProps extends SearchInputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const SearchInput: FunctionComponent<
  React.PropsWithChildren<SearchInputWithRefProps>
> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  isLoading = false,
  isActive,
  forwardedRef,
  onKeyDown,
  onClear,
  dataTestId = 'searchInput',
  placeholder = messages.placeholder,
  children,
  searchInputDefaultValue,
  componentThemeName,
  variant,
  searchFilter,
}) => {
  const theme = useTheme();
  const { handleChange, handleClear, valueSearched } = useSearchInput({
    onChange,
    onClear,
  });

  return (
    <$SearchInputContainer
      $componentThemeName={componentThemeName}
      $isActive={isActive}
    >
      <$SearchIconContainer>
        <SearchNewBoIcon color={theme.colors.paragraphTextColor} size="24px" />
        {isLoading && (
          <$SearchLoadingContainer>
            <LoaderIcon color={theme.colors.paragraphTextColor} size="15px" />
          </$SearchLoadingContainer>
        )}
      </$SearchIconContainer>

      <$SearchInput
        defaultValue={searchInputDefaultValue}
        placeholder={safeFormattedIntlString(placeholder)}
        onChange={handleChange}
        onBlur={onBlur}
        value={value || valueSearched}
        onFocus={onFocus}
        ref={forwardedRef}
        onKeyDown={onKeyDown}
        data-testid={`${dataTestId}_input`}
        $variant={variant}
      />
      <$SearchActionContainer>
        {onClear && (value || valueSearched) && (
          <$SearchClearButton
            icon={<CrossIcon size="16px" />}
            naked
            small
            type={BUTTON_TYPES.BUTTON}
            onClick={handleClear}
            dataTestId={`${dataTestId}_clearButton`}
          />
        )}
        {children}
      </$SearchActionContainer>

      <$SearchFilter>{searchFilter}</$SearchFilter>
    </$SearchInputContainer>
  );
};

SearchInput.displayName = 'SearchInput';

export default React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => <SearchInput forwardedRef={ref} {...props} />,
);
