import React, { PropsWithChildren, forwardRef } from 'react';
import { $FlexibleModalContent } from './FlexibleModalContent.styles';

interface ModalProps {
  width: number;
  height: number;
  top: number;
  left: number;
  isDragging: boolean;
  isOpen: boolean;
  onFocus?: () => void;
}

// eslint-disable-next-line react/display-name
export const FlexibleModalContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ModalProps>
>(
  (
    { isDragging, width, height, top, left, isOpen, onFocus, children },
    ref,
  ) => {
    if (isOpen) {
      return (
        <$FlexibleModalContent
          onClick={onFocus}
          draggable={isDragging}
          style={{ width, height, top, left }}
          ref={ref}
        >
          {children}
        </$FlexibleModalContent>
      );
    }

    return null;
  },
);
