import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { decelerateTimingFunctionAnimation } from '@savgroup-front-common/core/src/animations/timingFunction';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { styledGetCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

export const $SearchIconContainer = styled.div`
  position: absolute;
  left: 0.75rem;
  height: 32px;
  display: flex;
  align-items: center;
`;
export const $SearchLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  transform: translate3d(4px, 8px, 0);

  svg {
    color: #8a94a6;
  }
`;

export const $SearchClearButton = styled(Button)`
  opacity: 0;
  height: 32px;
  min-height: 32px;
  pointer-events: none;
  transition-duration: 0.3s;
  ${decelerateTimingFunctionAnimation};

  &&:focus {
    opacity: 1;
    pointer-events: all;
    background-color: transparent;
  }

  svg {
    color: ${({ theme }) => theme.newUI?.defaultColors.primary};
  }
`;
export const $SearchActionContainer = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  flex-direction: row;
  width: fit-content;
  button {
    max-width: ${styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    })};
  }
`;

const defaultVariant = css<{ $variant?: string }>`
  background: ${({ theme, $variant }) =>
    $variant === 'transparent'
      ? 'rgba(255, 255, 255, 0.15)'
      : theme.colors.white};
  border-color: ${({ $variant }) => ($variant ? 'transparent' : '#ccd2f9')};
`;

export const $SearchInput = styled.input<{ $variant?: string }>`
  border: 0;
  height: 32px;
  &:not([value='']) {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
  &[value=''] {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      pointer-events: none;
    }
  }

  ${defaultVariant};

  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0 0.5rem 0 3rem;
  flex: 1;

  transition-duration: 0.3s;
  border-width: 1px;
  border-style: solid;

  ${decelerateTimingFunctionAnimation};

  &:hover,
  &:focus {
    outline: 0;
  }

  @media ${media.maxWidth.xs} {
    padding: 0 2.5rem 0 3rem;
  }
`;

export const $SearchFilter = styled.div`
  && {
    position: absolute;
    right: 0;

    button {
      border: none;
      background: transparent;
      &:hover {
        border: none;
        background: transparent;
      }
    }
  }
`;

export const $SearchInputContainer = styled.div<{
  $componentThemeName?: string;
  $isActive?: boolean;
}>`
  max-width: ${({ $componentThemeName }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.MAX_WIDTH,
        })
      : null};
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  ${({ $isActive }) =>
    $isActive
      ? css`
          ${$SearchInput} {
            border-color: ${({ theme }) => theme.newUI?.defaultColors.primary};
            box-shadow: 0px 0px 6px 0px rgba(83, 105, 235, 0.3);
          }
        `
      : null}
  z-index: ${({ $isActive }) => ($isActive ? 12 : null)};

  &:hover {
    ${$SearchInput} {
      border-color: ${({ theme }) => theme.newUI?.defaultColors.primary};
    }
  }
`;
