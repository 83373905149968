import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useReducer,
} from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  createGenericContext,
  getFromLocalStorage,
} from '@savgroup-front-common/core/src/helpers';
import { LOCAL_STORAGE_KEYS, MessageType } from '@savgroup-front-common/types';

import { MENU_NAMES } from '../../components/Header/Menu/Menu.types';

import { MENU_CONF } from './FilesPage.constants';
import {
  ActionTypes,
  FilesPageContextReducer,
  FilesPageContextState,
} from './FilesPage.reducer';

export interface FilesPageContextArgs extends FilesPageContextState {
  handleAddTab: ({
    title,
    url,
    type,
  }: {
    title: string | MessageDescriptor | MessageType;
    url?: string;
    type: MENU_NAMES;
  }) => void;
  handleCloseTab: ({ url }: { url: string }) => void;
  handleSelectTab: ({ url }: { url: string }) => void;
  handleLoadHistorySearched: ({
    historyItems,
  }: {
    historyItems: string[];
  }) => void;
  handleUpdateCountTab: ({
    url,
    count,
  }: {
    url: string;
    count: number;
  }) => void;
}

const [useFilesPageContext, FilesPageProvider] =
  createGenericContext<FilesPageContextArgs>();

const FilesPageContextProvider: FunctionComponent<PropsWithChildren<any>> = ({
  children,
  defaultTabs = [],
}) => {
  const localTabs = getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.BO_TAB,
    defaultValue: [],
  });

  const tabsWithoutDefault = localTabs.filter(
    (tab) =>
      tab.to !== MENU_CONF[MENU_NAMES.FILES].url &&
      tab.to !== MENU_CONF[MENU_NAMES.TODOS].url &&
      tab.to !== MENU_CONF[MENU_NAMES.ORDERS].url,
  );

  const history = useHistory();
  const [state, dispatch] = useReducer(FilesPageContextReducer, {
    tabs: [...defaultTabs, ...tabsWithoutDefault],
    currentTab: undefined,
    historySearched: [],
  });

  const handleSelectTab = useCallback(
    ({
      url,
      title,
    }: {
      url: string;
      title?: string | MessageType | MessageDescriptor;
    }) => {
      dispatch({
        type: ActionTypes.SELECT_TAB,
        payload: {
          title,
          url,
        },
      });
    },
    [],
  );

  const handleUpdateCountTab = useCallback(
    ({ url, count }: { url: string; count: number }) => {
      dispatch({
        type: ActionTypes.UPDATE_COUNT_TAB,
        payload: {
          url,
          count,
        },
      });
    },
    [],
  );

  const handleAddTab = useCallback(
    ({
      title,
      url,
      type,
    }: {
      title: string | MessageDescriptor | MessageType;
      url?: string;
      type: MENU_NAMES;
    }) => {
      if (!url) {
        return;
      }
      const tabExist = state.tabs.some(
        (tab) => tab.to === url || tab.message === title,
      );

      if (tabExist) {
        handleSelectTab({ url, title });
        history.push(url);

        return;
      }
      dispatch({
        type: ActionTypes.ADD_TAB,
        payload: {
          title,
          url,
          type,
        },
      });
      history.push(url);
    },
    [handleSelectTab, history, state.tabs],
  );

  const handleCloseTab = useCallback(
    ({ url }: { url: string }) => {
      dispatch({
        type: ActionTypes.CLOSE_TAB,
        payload: {
          url,
          history,
        },
      });
    },
    [history],
  );

  const handleLoadHistorySearched = useCallback(
    ({ historyItems }: { historyItems: string[] }) => {
      dispatch({
        type: ActionTypes.LOAD_HISTORY,
        payload: {
          historyItems,
        },
      });
    },
    [],
  );

  return (
    <FilesPageProvider
      value={{
        ...state,
        handleAddTab,
        handleCloseTab,
        handleSelectTab,
        handleLoadHistorySearched,
        handleUpdateCountTab,
      }}
    >
      {children}
    </FilesPageProvider>
  );
};

FilesPageContextProvider.displayName = 'FilesPageContextProvider';

export { FilesPageContextProvider, useFilesPageContext };
