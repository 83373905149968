import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ActorTypes,
  COMMON_QUERY_PARAMS,
  CURRENCIES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoney,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}${COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE}`;

export interface Rule {
  supplierIds: any[];
  supplierId?: string;
  modelTypeUniqueName?: string;
  brandNames: any[];
  modelId?: string;
  eans: any[];
  salesChannel?: string;
  warrantyTypeName?: string;
  solutionTypeId?: string;
  repairable?: boolean;
  dateBegin?: string;
  dateEnd?: string;
  actorType: ActorTypes;
  actorId?: string;
  laborCompanyPrice: ReverseMoney;
  moveCompanyPrice: ReverseMoney;
  spareCompanyPrice: ReverseMoney;
  totalCompanyPrice: ReverseMoney;
  priceCurrencyCode?: CURRENCIES;
  actorPriority: number;
  selectable: boolean;
  overridable: boolean;
  purchaseStoreId?: string;
  storeActorZipCode?: string;
  storeActorZipCodes: any[];
  ownerZipCodes: any[];
  id: string;
  ruleSetId: string;
  sellerId?: string;
  clonedFrom?: string;
  modifiedColumns: any[];
  creationDate?: string;
}

interface CreateOrUpdateRuleCommandArg {
  sellerId: string;
  id?: string;
  values?: {
    [key: string]: string;
  };
  ruleType: string;
}

async function createOrUpdateRuleCommand({
  ruleType,
  id,
  values,
  sellerId,
}: CreateOrUpdateRuleCommandArg): Promise<
  BackResponse<Rule> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<Rule>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE, ruleType),
      { method: SUPPORTED_METHODS.POST, json: { id, values, sellerId } },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createOrUpdateRuleCommand;
