import { useEffect, useState } from 'react';
import Modeler from 'bpmn-js/dist/bpmn-modeler.production.min.js';
import { PaletteProvider } from '../helpers/CustomPalette';

export const useModeler = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null>;
}) => {
  const [modeler, setModeler] = useState<any | undefined>();

  useEffect(() => {
    if (containerRef.current && !modeler) {
      const newModeler = new (Modeler as any)({
        container: containerRef.current,
        keyboard: {
          bindTo: window,
        },
        textRenderer: {
          defaultStyle: {
            fontWeight: 'bold',
            fontSize: 11,
          },
        },
        additionalModules: [PaletteProvider],
      });

      setModeler(newModeler);
      (window as any).bpmnModeler = newModeler;
    }
  }, [containerRef, modeler]);

  return { modeler };
};
