import { defineMessages } from 'react-intl';

export default defineMessages({
  alerts: {
    id: 'view.dashboard.alerts.title',
    defaultMessage: 'Alerts',
  },
  todos: {
    id: 'view.nav.items.dashboard',
    defaultMessage: 'Dashboard',
  },
  files: {
    id: 'view.nav.items.folder',
    defaultMessage: 'Dossiers',
  },
  filesHistoric: {
    id: 'view.nav.items.historicFolder',
    defaultMessage: 'Archives',
  },
  bulkChanges: {
    id: 'view.nav.items.bulkChanges',
    defaultMessage: 'Bulk changes',
  },
  messages: {
    id: 'view.nav.items.messages',
    defaultMessage: 'Messages',
  },
  orders: {
    id: 'view.nav.items.orders',
    defaultMessage: 'Orders',
  },
  imports: {
    id: 'view.nav.items.imports',
    defaultMessage: 'Imports',
  },
  api: {
    id: 'view.nav.items.api',
    defaultMessage: 'Api',
  },
  settings: {
    id: 'view.nav.items.settings',
    defaultMessage: 'Settings',
  },
  spareParts: {
    id: 'view.nav.items.spareParts',
    defaultMessage: 'Spare parts',
  },
  sparePartRequests: {
    id: 'view.nav.items.sparePartRequests',
    defaultMessage: 'Requests',
  },
  sparePartRequestsToValidate: {
    id: 'view.nav.items.sparePartRequestsToValidate',
    defaultMessage: 'Requests to validate',
  },
  createSparePartRequest: {
    id: 'view.nav.items.createSparePartRequest',
    defaultMessage: 'Create request',
  },
  sparePartCatalog: {
    id: 'view.nav.items.sparePartCatalog',
    defaultMessage: 'Spare parts catalog',
  },
  sparePartInventories: {
    id: 'view.nav.items.sparePartInventories',
    defaultMessage: 'Spare parts inventories',
  },
  businessIntelligence: {
    id: 'view.nav.items.businessIntelligence',
    defaultMessage: 'Business intelligence',
  },
  sparePartStock: {
    id: 'view.nav.items.sparePartStock',
    defaultMessage: 'Stock',
  },
  account: {
    id: 'view.nav.items.account',
    defaultMessage: 'Account',
  },
  master: {
    id: 'view.nav.items.master',
    defaultMessage: 'Master',
  },
  users: {
    id: 'view.nav.items.users',
    defaultMessage: 'Users',
  },
  tenants: {
    id: 'view.nav.items.tenants',
    defaultMessage: 'Tenants',
  },
  tests: {
    id: 'view.nav.items.tests',
    defaultMessage: 'Tests',
  },
  sparePartsSettings: {
    id: 'view.settings.sparePartsSettings',
    defaultMessage: 'Spare parts',
  },
  help: {
    id: 'view.settings.help',
    defaultMessage: 'Help',
  },
  configurationConsole: {
    id: 'view.nav.configurationConsole',
    defaultMessage: 'Configuration console',
  },
  options: {
    id: 'view.configurationConsole.header.options',
    defaultMessage: 'Options',
  },
  configurationConsoleRules: {
    id: 'view.nav.configurationConsoleRules',
    defaultMessage: 'Rules',
  },
  configurationConsoleImport: {
    id: 'view.nav.configurationConsoleImport',
    defaultMessage: 'Import',
  },
  welcomePage: {
    id: 'view.nav.welcomePage',
    defaultMessage: 'Welcome',
  },
});
