import React, { FC, useRef } from 'react';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import { useBpmnModeler } from './BpmnModeler.hooks';
import { $BpmnModeler } from './BpmnModeler.styles';

interface BpmnModelerProps {
  url?: string;
  xml?: string;
  onClick: ({ name, id }: { name: string; id: string }) => void;
  onCreate: ({
    element,
    gfx,
    viewer,
  }: {
    element: any;
    gfx: any;
    viewer: any;
  }) => void;
  onUpdate: ({
    viewer,
    hasDebounce,
  }: {
    viewer: any;
    hasDebounce?: boolean;
  }) => void;
  onDeleteNode: ({
    element,
    xml,
    viewer,
  }: {
    element: any;
    xml: string;
    viewer: any;
  }) => void;
}

const BpmnModeler: FC<BpmnModelerProps> = ({
  url,
  xml,
  onClick,
  onCreate,
  onUpdate,
  onDeleteNode,
}) => {
  const containerRef = useRef(null);

  useBpmnModeler({
    containerRef,
    xml,
    url,
    onClick,
    onCreate,
    onUpdate,
    onDeleteNode,
  });

  return (
    <$BpmnModeler ref={containerRef} className="react-bpmn-diagram-container" />
  );
};

BpmnModeler.displayName = 'BpmnModeler';
export default BpmnModeler;
