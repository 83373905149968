import { PERMISSIONS, ROLES } from '@savgroup-front-common/constants';

import { types } from './actions';
import { LoginState } from './types';

const { LOGIN_SIGNED_IN, LOGIN_SIGNEDOUT } = types;

const initialState: LoginState = {
  id: '',
  isLoaded: false,
  isLoggedIn: false,
  userId: '',
  tenantId: '',
  firstname: '',
  lastname: '',
  mail: '',
  failure: false,
  errors: [],
  assignedStoresIds: [],
  accesses: [],
  rolePermissions: [],
  preferences: {
    language: '',
    communicationChannel: 'Email',
    lastSelectedStore: '',
  },

  roles: [],
  permissions: [],
  profiles: [],
};

interface LoginPayload {
  isLoaded: boolean;
  userId: string;
  mail: string;
  lastname: string;
  firstname: string;
  assignedStoresIds?: string[];
  preferences: {
    language: string;
    communicationChannel: 'Email';
    lastSelectedStore: string;
  };
  accesses: {
    role: ROLES;
    entityType: 'Seller';
    entityId: string;
  }[];
  rolePermissions: {
    roleId: string;
    roleName: string;
    builtInRole: ROLES;
    permissions: PERMISSIONS[];
    inheritedRoleIds: string[];
    inheritedRoleNames: string[];
  }[];
  failure: boolean;
  errors: unknown[];
}

function signedInApply(
  state: LoginState,
  { payload }: { payload: LoginPayload },
): LoginState {
  const { permissions, roles, profiles } = payload.rolePermissions?.reduce<{
    permissions: PERMISSIONS[];
    roles: ROLES[];
    profiles: string[];
  }>(
    (acc, rolePermission) => {
      const permissions = (rolePermission?.permissions || []).reduce<
        PERMISSIONS[]
      >((acc, permission) => {
        if (acc.includes(permission)) {
          return acc;
        }

        return [...acc, permission];
      }, acc.permissions);

      const roles =
        rolePermission.builtInRole !== ROLES.CUSTOM_PROFILE
          ? [...acc.roles, rolePermission.builtInRole]
          : acc.roles;
      const profiles =
        rolePermission.builtInRole === ROLES.CUSTOM_PROFILE
          ? [...acc.profiles, rolePermission.roleName]
          : acc.profiles;

      return { permissions, roles, profiles };
    },
    { permissions: [], roles: [], profiles: [] },
  );

  return {
    ...state,
    ...payload,
    isLoggedIn: !payload.failure,
    permissions,
    roles,
    profiles,
  };
}

function signedOutApply() {
  return initialState;
}

export default function mainReducer(
  state = initialState,
  action: any,
): LoginState {
  switch (action.type) {
    case LOGIN_SIGNED_IN:
      return signedInApply(state, action);
    case LOGIN_SIGNEDOUT:
      return signedOutApply();

    default:
      return state;
  }
}
