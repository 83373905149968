import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import { createSelector } from 'reselect';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ATTACHMENTS_PLACEMENTS } from '@savgroup-front-common/constants/src/shared';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import {
  translationsIssuesSelector,
  translationsReasonsSelector,
  translationsSolutionsSelector,
  translationsWarrantiesSelector,
} from '@savgroup-front-common/core/src/domains/claims/selectors';
import { Selectors as i18nSelectors } from '@savgroup-front-common/core/src/domains/i18n';
import { carrierFormatter } from '@savgroup-front-common/core/src/formatters';
import { regroupProducts } from '@savgroup-front-common/core/src/helpers';
import { CARRIERS } from '@savgroup-front-common/types';

import * as fileDetailsSelectors from '../fileInfo/fileDetails';
import {
  marketplaceCapabilities,
  ticketsByOrder,
} from '../marketplaceTickets/selectors';
import * as ModelsSelectors from '../models/selectors';
import { selectOrderById } from '../orders/selectors';
import { refundsStateSelector } from '../refunds/selectors';

import { productsInfoSelectorCombiner } from './selectors';

const getProductIconUrl = (modelTypeId) =>
  `${APIConfiguration.catalogCDN}product-icons/${modelTypeId}.svg`;

export const businessStateSelector = (state) => state.business;

export const claimStateSelector = createSelector(
  businessStateSelector,
  (state) => state.claim,
);

export const currentOrderIdSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector],
  (file) => get(file, 'orderId', ''),
);
export const selectOrderIdByFileId = createSelector(
  [fileDetailsSelectors.getFileByFileId],
  (file) => get(file, 'orderId', ''),
);

export const selectCurrentOrderSelector = createSelector(
  [(state) => state, currentOrderIdSelector],
  (state, orderId) => {
    return selectOrderById(state, { orderId });
  },
);

export const marketplaceCapabilitiesSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector, marketplaceCapabilities],
  (file, marketplaceCapabilities) => {
    const marketplace = get(file, 'marketPlace');
    const sellerId = get(file, 'sellerId');

    if (marketplace && sellerId) {
      return (
        marketplaceCapabilities &&
        marketplaceCapabilities.getIn([marketplace, sellerId])
      );
    }

    return null;
  },
);

export const currentFullClaims = createSelector(
  [fileDetailsSelectors.currentFileSelector, ClaimsSelectors.fullClaims],
  (file, fullClaims) => {
    const claimIds = map(get(file, 'fileProducts'), (fileProduct) =>
      get(fileProduct, 'claimId'),
    );

    return {
      isLoaded: every(claimIds, (claimId) =>
        fullClaims.getIn([claimId, 'isLoaded']),
      ),
      value: map(claimIds, (claimId) => fullClaims.getIn([claimId, 'value'])),
    };
  },
);

export const selectFullClaimsByFileId = createSelector(
  [fileDetailsSelectors.getFileByFileId, ClaimsSelectors.fullClaims],
  (file, fullClaims) => {
    const claimIds = map(get(file, 'fileProducts'), (fileProduct) =>
      get(fileProduct, 'claimId'),
    );

    return {
      isLoaded: every(claimIds, (claimId) =>
        fullClaims.getIn([claimId, 'isLoaded']),
      ),
      value: map(claimIds, (claimId) => fullClaims.getIn([claimId, 'value'])),
    };
  },
);

const orderProductsSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector, (state) => state],
  (file, state) => {
    return selectOrderById(state, { orderId: file?.orderId })?.products;
  },
);

export const claimsCardTableStateSelector = createSelector(
  [
    fileDetailsSelectors.currentFileSelector,
    orderProductsSelector,
    ModelsSelectors.selectModels,
    ClaimsSelectors.fullClaims,
    translationsIssuesSelector,
    translationsReasonsSelector,
    translationsWarrantiesSelector,
    translationsSolutionsSelector,
    ClaimsSelectors.claims,
  ],
  (
    file,
    orderProducts,
    models,
    fullClaims,
    issues,
    reasons,
    warranties,
    solutions,
    getClaims,
  ) => {
    const fileProducts = file?.fileProducts || [];
    const filteredFileProducts = fileProducts.filter(
      ({ modelId }) => models[modelId]?.value,
    );

    return {
      isLoaded: Boolean(file),
      data: regroupProducts(
        filteredFileProducts.map((filteredFileProduct) => {
          const {
            modelId,
            modelTypeId,
            issueId,
            reasonId,
            solutionTypeId,
            warrantyTypeId,
            ownerProductId,
            claimId,
          } = filteredFileProduct;

          const { modelTypeName, brandName, name } = models[modelId]?.value;
          const orderProduct = find(orderProducts, { ownerProductId });
          const ignoreDateRulesChecking = get(
            getClaims.getIn([claimId, 'value']),
            'ignoreDateRulesChecking',
          );

          const comment = get(
            find(get(fullClaims.getIn([claimId, 'value']), 'comments'), {
              type: 'ReasonComment',
            }),
            'content',
          );
          const documents = filter(
            get(fullClaims.getIn([claimId, 'value']), 'documents'),
            (document) => document.originalFileName,
          );

          return {
            imageSrc: getProductIconUrl(modelTypeId),
            productType: modelTypeName,
            productBrand: brandName,
            productModel: name,
            warrantyType: {
              name: get(warranties, warrantyTypeId),
              ignoreDateRulesChecking: Boolean(ignoreDateRulesChecking),
            },
            issue: get(issues, issueId),
            reason: get(reasons, reasonId),
            solution: get(solutions, solutionTypeId),
            sellerProductId: get(orderProduct, 'sellerProductId'),
            ownerProductId,
            comment,
            documents: {
              data: map(documents, (document) => ({
                attachmentId: APIConfiguration.base + get(document, 'url'),
                fileId: get(file, 'fileId'),
                filename: get(document, 'originalFileName'),
                type: ATTACHMENTS_PLACEMENTS.TRANSPORT_DOCUMENTS,
                origin: ATTACHMENTS_PLACEMENTS.MYACCOUNT_USER_DOCUMENTS,
              })),
              isLoaded: fullClaims.getIn([claimId, 'isLoaded']),
            },
          };
        }),
      ),
    };
  },
);

export const refundsTableStateSelector = createSelector(
  [refundsStateSelector, fileDetailsSelectors.currentFileIdSelector],
  (refunds, fileId) => {
    const refundsData = get(refunds, ['refund', fileId], {});
    const refundHistory = refundsData?.history?.at(0);

    return {
      date: refundHistory?.date,
      amount: refundHistory?.amount,
      comment: refundHistory?.comment,
      refunded: refundsData?.refunded,
      marketingOfferType: refundsData?.marketingOfferType,
      currencyCode: refundsData?.currencyCode,
    };
  },
);

export const depositTransportSelector = createSelector(
  [
    fileDetailsSelectors.currentFileIdSelector,
    fileDetailsSelectors.currentFileSelector,
    fileDetailsSelectors.currentFileHandlingSelector,
    i18nSelectors.countryList,
  ],
  (fileId, file, transport, countryList) => {
    const trackingInfos = get(file, 'trackingNumbers');
    const trackingInfo = find(trackingInfos, {
      handlingDirection: 'FromOwner',
    });
    const handling = transport.get('value', {});
    const handlingMode = 'deposit';
    const deposit = get(handling, handlingMode, {});
    const address = get(deposit, 'address', {});
    const depositAddress = carrierFormatter.formatAddress(address, countryList);

    return {
      carrierCompany: get(trackingInfo, 'carrier', CARRIERS.EXTERNAL_CARRIER),
      depositAddress,
      handlingMode,
    };
  },
);

export const deliveryTransportSelector = createSelector(
  [
    fileDetailsSelectors.currentFileIdSelector,
    fileDetailsSelectors.currentFileSelector,
    fileDetailsSelectors.currentFileHandlingSelector,
    i18nSelectors.countryList,
  ],
  (fileId, file, transport, countryList) => {
    const trackingInfos = get(file, 'trackingNumbers');
    const trackingInfo = find(trackingInfos, { handlingDirection: 'ToOwner' });
    const handlingMode = 'delivery';
    const handling = transport.get('value', {});

    const delivery = get(handling, handlingMode, {});
    const address = get(delivery, 'address', {});
    const deliveryAddress = carrierFormatter.formatAddress(
      address,
      countryList,
    );

    return {
      carrierCompany: get(trackingInfo, 'carrier', CARRIERS.EXTERNAL_CARRIER),
      deliveryAddress,
      handlingMode,
    };
  },
);

export const currentOrderLineReferenceSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector],
  (file) => get(file, 'orderLineReference', ''),
);

export const currentTicketsByOrderSelector = createSelector(
  [ticketsByOrder, currentOrderIdSelector],
  (tickets, orderId) => tickets.get(orderId),
);

export const selectOrderLinkById = createSelector(
  [selectOrderById],
  (order) => {
    return order?.externalOrderLinkUrl;
  },
);

export const currentProductsInfoSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector, selectCurrentOrderSelector],
  productsInfoSelectorCombiner,
);

export const currentTicketSelector = createSelector(
  [currentTicketsByOrderSelector, currentOrderLineReferenceSelector],
  (currentTicketsByOrder, orderLineReference) => ({
    ticketId:
      currentTicketsByOrder &&
      get(currentTicketsByOrder.get('value'), [orderLineReference, 'id']),
    isLoaded: currentTicketsByOrder && currentTicketsByOrder.get('isLoaded'),
  }),
);

export const commercialSolutionTableStateSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector],
  (file) => {
    const commercialSolution = file?.commercialSolution;
    const solutionType = commercialSolution?.solutionType;
    const date = commercialSolution?.changeTimeUtc;
    const amount = commercialSolution?.refundedAmount?.amount || 0;
    const currencyCode = commercialSolution?.refundedAmount?.currency;
    const percentageValue = commercialSolution?.percentageValue || 0;
    const marketingOfferType = commercialSolution?.marketingOfferType;
    const marketingOfferState = commercialSolution?.marketingOfferState;

    return {
      isLoaded: Boolean(file),
      data: commercialSolution && {
        date,
        amount,
        percentage: percentageValue,
        solutionType,
        marketingOfferType,
        marketingOfferState,
        currencyCode,
      },
    };
  },
);

export const commercialGestureTableStateSelector = createSelector(
  [fileDetailsSelectors.currentFileSelector],
  (file) => {
    const commercialGesture = file?.commercialGesture;
    const solutionType = commercialGesture?.solutionType;
    const date = commercialGesture?.refundDateUtc;
    const amount = commercialGesture?.refundedAmount?.amount || 0;
    const currencyCode = commercialGesture?.refundedAmount?.currency;
    const percentageValue = commercialGesture?.percentageValue || 0;
    const marketingOfferType = commercialGesture?.marketingOfferType;
    const marketingOfferState = commercialGesture?.marketingOfferState;

    return {
      isLoaded: Boolean(file),
      data: commercialGesture && {
        date,
        amount,
        percentage: percentageValue,
        solutionType,
        marketingOfferType,
        marketingOfferState,
        currencyCode,
      },
    };
  },
);
