import { useCallback } from 'react';
import { BpmnElementType, BpmnEvents } from '../BpmnModeler.types';

export const useEventModeler = ({
  modeler,
  onClick,
  onCreate,
  onUpdate,
  onDeleteNode,
}: {
  modeler?: any;
  onClick?: ({ name, id }: { name: string; id: string }) => void;
  onCreate?: ({
    element,
    gfx,
    viewer,
  }: {
    element: any;
    gfx: any;
    viewer: any;
  }) => void;
  onUpdate: ({
    viewer,
    hasDebounce,
  }: {
    viewer: any;
    hasDebounce?: boolean;
  }) => void;
  onDeleteNode: ({
    element,
    xml,
    viewer,
  }: {
    element: any;
    xml: string;
    viewer: any;
  }) => void;
}) => {
  const eventBus = modeler?.get('eventBus');
  const interactionEvents = modeler?.get('interactionEvents');

  const handleClick = useCallback(
    (e: any) => {
      const { type, di, id } = e.element;
      const { name } = di.bpmnElement;

      if (onClick && type !== BpmnElementType.process) {
        onClick({ name, id });
      }
    },
    [onClick],
  );

  const handleCreate = useCallback(
    async (context: any) => {
      const { element, gfx } = context;

      if (onCreate) {
        setTimeout(async () => {
          onCreate({
            element,
            gfx,
            viewer: modeler,
          });
        });
      }
    },
    [modeler, onCreate],
  );

  const handleUpdate = useCallback(
    ({
      element,
      gfx,
      hasDebounce = true,
    }: {
      element?: any;
      gfx?: any;
      hasDebounce?: boolean;
    }) => {
      if (onUpdate) {
        setTimeout(async () => {
          onUpdate({ viewer: modeler, hasDebounce });

          if (element && gfx) {
            interactionEvents.createDefaultHit(element, gfx);
          }
        });
      }
    },
    [interactionEvents, modeler, onUpdate],
  );

  const handleDelete = useCallback(
    async (context: any) => {
      const { element } = context;

      setTimeout(async () => {
        const result = await modeler?.saveXML();

        if (onDeleteNode && result?.xml) {
          onDeleteNode({
            element,
            xml: result.xml,
            viewer: modeler,
          });
        }
      });
    },
    [modeler, onDeleteNode],
  );

  const handleUpdateProps = useCallback(
    async ({
      element,
      gfx,
      type,
      hasDebounce = true,
      ...props
    }: {
      element: any;
      gfx: any;
      type?: string;
      hasDebounce?: boolean;
      props: any;
    }) => {
      if (type !== BpmnEvents.updateProps) {
        return;
      }

      const modeling = modeler?.get('modeling');

      await modeling?.updateProperties(element, props);

      handleUpdate({ element, gfx, hasDebounce });
    },
    [handleUpdate, modeler],
  );

  const subscribeToBpmnEvents = useCallback(() => {
    if (eventBus) {
      eventBus.on(BpmnEvents.click, handleClick);
      eventBus.on(BpmnEvents.create, handleCreate);
      eventBus.on(BpmnEvents.update, handleUpdate);
      eventBus.on(BpmnEvents.delete, handleDelete);
      eventBus.on(BpmnEvents.updateProps, handleUpdateProps);
    }
  }, [
    eventBus,
    handleClick,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleUpdateProps,
  ]);

  return { subscribeToBpmnEvents };
};
