import {
  ACTOR_TYPES,
  CARRIER_PRODUCT_TYPE,
  HANDLING_MODES,
  MODULE_KIND,
} from '@savgroup-front-common/constants';

import { AddressInfoDto } from './AddressInfo';
import { ProductAdequacy } from './File';

export enum CARRIERS {
  EXTERNAL_CARRIER = 'ExternalCarrier',
  UPS = 'ups',
  CHRONOPOST = 'chronopost',
  COLISSIMO = 'colissimo',
  WARNING = 'Warning',
  VIR = 'vir',
}

enum TRANSPORT_REACH {
  UNKNOWN = 'Unknown',
  HOME = 'Home',
  POST_OFFICE_COUNTER = 'PostOfficeCounter',
  PRIVATE_CARRIER_COUNTER = 'PrivateCarrierCounter',
}

export interface TrackingInfo {
  trackingNumber?: string;
  trackingUrl?: string;
  productsSKU?: string[];
  trackingStatuses?: {
    createdDate: Date;
    eventDate: Date;
    internalCode?: string;
    carrierCode?: string;
    isDeliveryEvent: boolean;
    isBillable: boolean;
    transportAdequacy: ProductAdequacy;
  }[];
}

export interface ShippingLegInfo {
  creationDateUtc?: Date;
  useTimeUtc?: Date;
  deliveryDateUtc?: Date;
  moduleKind: MODULE_KIND;
  handlingMode: HANDLING_MODES;
  carrierService: CARRIERS;
  carrierOperator: CARRIERS;
  carrierLogoUrl?: string;
  fromActorType: ACTOR_TYPES;
  toActorType: ACTOR_TYPES;
  senderAddress?: AddressInfoDto;
  senderLogoUrl?: string;
  recipientAddress: AddressInfoDto;
  recipientLogoUrl?: string;
  pickupPointId?: string;
  pickupPointAddress?: AddressInfoDto;
  pickupPointLogoUrl?: string;
  labelUrl?: string;
  transportMethodName?: string;
  transportReach?: TRANSPORT_REACH;
  carrierProductType?: CARRIER_PRODUCT_TYPE;
  trackingInfos?: TrackingInfo[];
  handlingWeight?: number;
  cO2EmissionsInKgByKm?: number;
  homePickupInfos?: {
    startTimeInLocalRecipientTimezone: Date;
    endTimeInLocalRecipientTimezone: Date;
    pickupRequestNumber?: string;
    pickupRequestUrl?: string;
    cancelled: boolean;
  }[];
}

export interface FileShipping {
  firstLegShipping: ShippingLegInfo;
  middleLegShippings?: ShippingLegInfo[];
  lastLegShipping?: ShippingLegInfo;
}
