import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import React, { FC } from 'react';
import {
  $Button,
  $SearchResultContent,
  $SearchResultHeader,
  $SearchResultIcon,
  $SearchResultValue,
  $SearchResultValueItem,
  $SearchResultWrapper,
  $Separator,
} from './SearchResult.styles';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import ResultIcon from './ResultIcon/ResultIcon';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import messages from './messages';
import ResultHighlight from './ResultHighlight/ResultHighlight';
import { ResultSearchType } from '../SearchWithDimmer.types';

const SearchResult: FC<{
  results: SearchResultList<ResultSearchType>[];
  searchValue?: string;
  onClick: (hit: ResultSearchType) => void;
  onShowMore?: () => void;
}> = ({ results, searchValue, onClick, onShowMore }) => {
  return results.length ? (
    <>
      <$SearchResultWrapper>
        {results.map((result) =>
          result.hitCount ? (
            <$SearchResultContent
              key={result.title?.defaultMessage || `${result.hitCount}`}
            >
              <$Separator />
              <$SearchResultHeader
                $color={result.iconColor?.color}
                $bgColor={result.iconColor?.bgColor}
              >
                {result.title && (
                  <SafeFormattedMessageWithoutSpread message={result.title} />
                )}
                <Tag isSmall withoutMargin>
                  {result.hitCount}
                </Tag>
              </$SearchResultHeader>
              <$SearchResultValue>
                {result.hits?.map((hit) => (
                  <$SearchResultValueItem
                    key={hit.searchReference}
                    onClick={() => onClick(hit)}
                  >
                    <$SearchResultIcon $bgColor={result.iconColor?.bgColor}>
                      {result.icon && (
                        <ResultIcon
                          iconName={result.icon}
                          color={result.iconColor?.color}
                        />
                      )}
                    </$SearchResultIcon>
                    <ResultHighlight
                      searchValue={searchValue}
                      hit={hit}
                      color={result.iconColor?.color}
                    />
                  </$SearchResultValueItem>
                ))}
                {result.hitCount - (result.hits?.length || 0) > 0 && (
                  <$Button
                    naked
                    small
                    onClick={onShowMore}
                    type={BUTTON_TYPES.BUTTON}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.showMore}
                      values={{
                        value: result.hitCount - (result.hits?.length || 0),
                      }}
                    />
                  </$Button>
                )}
              </$SearchResultValue>
            </$SearchResultContent>
          ) : (
            <></>
          ),
        )}
      </$SearchResultWrapper>
    </>
  ) : (
    <></>
  );
};

SearchResult.displayName = 'SearchResult';
export default SearchResult;
