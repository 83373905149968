import createOwner from './createOwner';
import { createOwnerForOrderCommand } from './createOwnerForOrderCommand';
import getListOwnerProductsQuery from './getListOwnerProductsQuery';
import getOwnerByEmail from './getOwnerByEmail';
import getOwnerOrderSummariesQuery from './getOwnerOrderSummariesQuery';
import getOwnerProductByIdQuery from './getOwnerProductByIdQuery';
import getOwnersByPhoneNumber from './getOwnersByPhoneNumber';
import getOwnersQuery from './getOwnersQuery';
import getStockOwnerForCurrentUserQuery from './getStockOwnerForCurrentUserQuery';
import importOrderCommand from './importOrderCommand';
import listOrdersQuery from './listOrdersQuery/listOrdersQuery';
import addProductsToOrder from './order/addProductsToOrder';
import assignOrderToOwner from './order/assignOrderToOwner';
import createOrder from './order/createOrder';
import createOrderWithoutReference from './order/createOrderWithoutReference';
import getOrderDetails from './order/getOrderDetails';
import getOrderDetailsLegacy from './order/getOrderDetails.legacy';
import getOrderDetailsQuery from './order/getOrderDetailsQuery/getOrderDetailsQuery';
import getOrdersByQuery from './order/getOrdersByQuery';
import { getOwnerById } from './order/getOwnerById/getOwnerById';
import { getOwnerByIdLegacy } from './order/getOwnerById/getOwnerByIdLegacy';
import {
  getOrderIdByReceiptInformation,
  getOrdersByReferenceAndSeller,
} from './order/order.api';
import updateOwnerProductsOwnerCommand from './order/updateOwnerProductsOwnerCommand';
import { generateDemoOrderCommand } from './sellers/generateDemoOrderCommand';
import { setOwnerNotificationLanguage } from './setOwnerNotificationLanguage';
import { updateOwnerAddressCommand } from './updateOwnerAddressCommand';
import upsertOwner from './upsertOwner';
import { createVideoSessionCommand } from './video/createVideoSessionCommand';
import { forceDisconnectClientsCommand } from './video/forceDisconnectClientsCommand';
import { getVideoSessionHistoryQuery } from './video/getVideoSessionHistoryQuery';
import { getVideoSessionModeratorTokenQuery } from './video/getVideoSessionModeratorTokenQuery';
import { getVideoSessionPublisherTokenQuery } from './video/getVideoSessionPublisherTokenQuery';

export const OwnerService = {
  updateOwnerAddressCommand,
  getOrderDetailsQuery,
  setOwnerNotificationLanguage,

  createOwner,
  upsertOwner,
  getOwnerByEmail,
  getOwnersByPhoneNumber,

  getOwnerByIdLegacy,
  getOwnerById,

  getOwnersQuery,
  getOwnerOrderSummariesQuery,
  importOrderCommand,
  listOrdersQuery,
  getListOwnerProductsQuery,
  getOrderDetailsLegacy,
  addProductsToOrder,
  assignOrderToOwner,
  getOrderDetails,
  createOrderWithoutReference,
  createOrder,
  getOrdersByQuery,
  getOrderIdByReceiptInformation,
  getOrdersByReferenceAndSeller,
  createOwnerForOrderCommand,
  updateOwnerProductsOwnerCommand,
  getOwnerProductByIdQuery,
  getStockOwnerForCurrentUserQuery,

  createVideoSessionCommand,
  getVideoSessionModeratorTokenQuery,
  getVideoSessionPublisherTokenQuery,
  getVideoSessionHistoryQuery,
  forceDisconnectClientsCommand,

  generateDemoOrderCommand,
};
