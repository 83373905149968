import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/DiagnosticTreeConfiguration/bpmn`;

interface UpsertBpmnForDiagnosticTreeConfigurationFromConsoleCommandArg {
  sellerId: string;
  payload: Record<string, string>;
}

async function upsertBpmnForDiagnosticTreeConfigurationFromConsoleCommand({
  sellerId,
  payload,
}: UpsertBpmnForDiagnosticTreeConfigurationFromConsoleCommandArg): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<string>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      { method: SUPPORTED_METHODS.POST, json: payload },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default upsertBpmnForDiagnosticTreeConfigurationFromConsoleCommand;
