import { Reducer } from 'react';
import { MessageDescriptor } from 'react-intl';

import { setToLocalStorage } from '@savgroup-front-common/core/src/helpers';
import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { LOCAL_STORAGE_KEYS, MessageType } from '@savgroup-front-common/types';

import { MENU_NAMES } from '../../components/Header/Menu/Menu.types';
import { MenuItem } from '../../components/Tabs/Tabs.types';

export enum ActionTypes {
  ADD_TAB = 'AddTab',
  CLOSE_TAB = 'CloseTab',
  SELECT_TAB = 'SelectTab',
  LOAD_HISTORY = 'LoadHistory',
  UPDATE_COUNT_TAB = 'UpdateCountTab',
}

export interface FilesPageContextState {
  tabs: MenuItem[];
  currentTab?: string;
  historySearched: string[];
}

type Action =
  | {
      type: ActionTypes.ADD_TAB;
      payload: {
        title: string | MessageType | MessageDescriptor;
        url: string;
        type: MENU_NAMES;
      };
    }
  | {
      type: ActionTypes.CLOSE_TAB;
      payload: {
        url: string;
        history: any;
      };
    }
  | {
      type: ActionTypes.SELECT_TAB;
      payload: {
        title?: string | MessageType | MessageDescriptor;
        url: string;
      };
    }
  | {
      type: ActionTypes.UPDATE_COUNT_TAB;
      payload: {
        url: string;
        count: number;
      };
    }
  | {
      type: ActionTypes.LOAD_HISTORY;
      payload: {
        historyItems: string[];
      };
    };

export const FilesPageContextReducer: Reducer<FilesPageContextState, Action> = (
  state,
  action,
) => {
  switch (action.type) {
    case ActionTypes.ADD_TAB: {
      const tabs = [
        ...state.tabs.map((menu) => ({ ...menu, isActive: false })),
        {
          message: action.payload.title,
          isActive: true,
          isRemovable: true,
          isDraggable: true,
          to: action.payload.url,
          count: 0,
          dataTestId: `newTab_${action.payload.title}`,
          isExternal: false,
          type: action.payload.type,
        },
      ];

      setToLocalStorage({
        key: LOCAL_STORAGE_KEYS.BO_TAB,
        value: tabs,
      });

      return {
        ...state,
        currentTab: action.payload.url,
        tabs,
      };
    }
    case ActionTypes.CLOSE_TAB: {
      const reversTabs = state.tabs.slice().reverse();
      const currentTab = reversTabs.find(
        (menu) => menu.to === action.payload.url,
      );
      const isCurrentActive = currentTab?.isActive;

      if (!isCurrentActive) {
        const tabs = state.tabs.filter(
          (menu) => menu.to !== action.payload.url,
        );

        setToLocalStorage({
          key: LOCAL_STORAGE_KEYS.BO_TAB,
          value: tabs,
        });

        return {
          ...state,
          tabs: state.tabs.filter((menu) => menu.to !== action.payload.url),
        };
      }

      const newTab = reversTabs.find(
        (menu) =>
          menu.type === currentTab?.type && menu.to !== action.payload.url,
      );

      action.payload.history.push(newTab?.to);

      const tabs = state.tabs
        .filter((menu) => menu.to !== action.payload.url)
        .map((tab) =>
          tab.to === newTab?.to
            ? { ...tab, isActive: true }
            : { ...tab, isActive: false },
        );

      setToLocalStorage({
        key: LOCAL_STORAGE_KEYS.BO_TAB,
        value: tabs,
      });

      return {
        ...state,
        currentTab: newTab?.to,
        tabs: state.tabs
          .filter((menu) => menu.to !== action.payload.url)
          .map((tab) =>
            tab.to === newTab?.to
              ? { ...tab, isActive: true }
              : { ...tab, isActive: false },
          ),
      };
    }
    case ActionTypes.SELECT_TAB: {
      const isTabExist = state.tabs.some(
        (tab) => tab.to === action.payload.url,
      );
      const title = action.payload.title || '';

      return {
        ...state,
        currentTab: action.payload.url,
        tabs: isTabExist
          ? state.tabs.map((menu) => {
              if (menu.to !== action.payload.url) {
                return { ...menu, isActive: false };
              }

              return { ...menu, isActive: true };
            })
          : [
              ...state.tabs.map((menu) => ({ ...menu, isActive: false })),
              {
                message: title,
                isActive: true,
                isRemovable: true,
                isDraggable: true,
                to: action.payload.url,
                count: 0,
                dataTestId: `newTab_${title}`,
                isExternal: false,
              },
            ],
      };
    }
    case ActionTypes.LOAD_HISTORY: {
      return {
        ...state,
        historySearched: action.payload.historyItems,
      };
    }
    case ActionTypes.UPDATE_COUNT_TAB: {
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.to === action.payload.url) {
            return { ...tab, count: action.payload.count };
          }

          return tab;
        }),
      };
    }
    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
