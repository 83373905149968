import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  FEATURE_FLAGS_FOR_SELLER,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}features/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/${COMMON_QUERY_PARAMS.ENTITY_ID}`;

interface FeatureDecisionsByEntityResponseValue {
  key: FEATURE_FLAGS_FOR_SELLER;
  value: boolean;
}

interface GetFeatureFlagsByIdQueryPayload {
  entityId: string;
  entityType?: string;
}

const getFeatureFlagsByIdQuery = async ({
  entityId,
  entityType = ACTOR_TYPES.SELLER,
}: GetFeatureFlagsByIdQueryPayload): Promise<
  BackResponse<FeatureDecisionsByEntityResponseValue[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ENTITY_TYPE,
      entityType,
    ).replace(COMMON_QUERY_PARAMS.ENTITY_ID, entityId);

    const response = await apiCall<
      BackResponse<FeatureDecisionsByEntityResponseValue[]>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getFeatureFlagsByIdQuery;
