import { useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';

export const useSearchHistory = ({
  searchItem,
  onHistorySet,
  historySearched,
}: {
  searchItem?: string;
  onHistorySet: (items: string[]) => void;
  historySearched: string[];
}) => {
  const [lastItemsSearched, setLastItemsSearched] = useState<string[]>([]);

  useDeepCompareEffect(() => {
    if (searchItem) {
      if (!lastItemsSearched.includes(searchItem)) {
        setLastItemsSearched([searchItem, ...lastItemsSearched].slice(0, 3));
      }
    }
    if (lastItemsSearched.length === 0 && historySearched.length !== 0) {
      setLastItemsSearched(historySearched);
    }
  }, [historySearched, lastItemsSearched, searchItem]);

  useEffect(() => {
    onHistorySet(lastItemsSearched);
  }, [lastItemsSearched, onHistorySet]);

  return { lastItemsSearched };
};
