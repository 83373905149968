import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { SessionParticipantDto } from '@savgroup-front-common/types/src/Owner/SessionParticipantDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}video/session/token`;

interface TokenSummaryDto {
  token: string;
  url: string;
  applicationId: string;
  participants: SessionParticipantDto[];
}

interface GetVideoSessionModeratorTokenQueryPayload {
  sessionId: string;
}

export const getVideoSessionModeratorTokenQuery = async ({
  sessionId,
}: GetVideoSessionModeratorTokenQueryPayload): Promise<
  BackResponse<TokenSummaryDto> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<TokenSummaryDto>>(
      buildUrl(ENDPOINT, { sessionId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
