import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { AssignableUserProfile } from '../../../domains/userManagement';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}users/listUserProfile`;

interface ListUserProfileQueryPayload {
  tenantId: string;
}

export const listUserProfileQuery = async ({
  tenantId,
}: ListUserProfileQueryPayload): Promise<
  BackResponse<AssignableUserProfile[]> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<AssignableUserProfile[]>>(
      buildUrl(ENDPOINT, { tenantId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
