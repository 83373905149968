import { POSITIONS } from '@savgroup-front-common/constants';
import { rem } from 'polished';
import styled from 'styled-components';
import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation';

interface ResultContainerProps extends SimpleAnimationProps {
  $size: number;
  $minWidth?: number;
  $isVisible?: boolean;
  $position: POSITIONS;
  $top?: number;
}

export const $ResultContainer = styled.div<ResultContainerProps>`
  & {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.newUI?.iconColors.primary.bgColor};
  border-radius: ${rem(8)};
  padding: 8px 16px 16px 16px;
  position: absolute;
  top: ${({ $top }) => ($top ? rem($top) : rem(40))};
  left: ${({ $position }) => ($position === POSITIONS.LEFT ? 0 : null)};
  right: ${({ $position }) => ($position === POSITIONS.RIGHT ? 0 : null)};
  width: ${({ $minWidth }) => ($minWidth ? rem($minWidth) : '100%')};
  height: ${({ $size = 0 }) => rem($size)};
  max-height: calc(100vh - ${rem(100)});
  z-index: 12;
  display: flex;
  flex-direction: column;
  visibility: ${({ $isVisible, $size }) =>
    $isVisible && $size !== 0 ? 'visible' : 'hidden'};
  gap: 8px;

  transition: 0.25s height ease-in-out;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
`;

export const $SearchWithDimmerWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
`;
