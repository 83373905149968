import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}video/session`;

interface VideoSessionSummaryDto {
  sessionId: string;
  url: string;
}

interface CreateVideoSessionCommandPayload {
  fileId: string;
  phone: string;
}

export const createVideoSessionCommand = async ({
  fileId,
  phone,
}: CreateVideoSessionCommandPayload): Promise<
  BackResponse<VideoSessionSummaryDto> | BackResponseFailure
> => {
  try {
    const forceLocalhost = window.location.hostname === 'localhost';

    return await apiCall<BackResponse<VideoSessionSummaryDto>>(ENDPOINT, {
      method: SUPPORTED_METHODS.PUT,
      json: { fileId, phone, forceLocalhost },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
