import { useDifferedValue } from '@savgroup-front-common/core/src/hooks';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import { useState } from 'react';
import { ResultSearchType } from './SearchWithDimmer.types';

export const useSearchWithDimmer = ({
  query,
  results,
  containerHeight,
  onSearch,
  onClear,
  onHistoryChange,
}: {
  query?: string;
  containerHeight: number;
  results?: SearchResultList<ResultSearchType>[];
  onClear?: () => void;
  onSearch?: (value: string) => void;
  onHistoryChange?: (items: string[]) => void;
}) => {
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [historyNumber, setHistoryNumber] = useState(0);
  const [valueSearched, setValueSearched] = useState(query);
  const searchValue = useDifferedValue(valueSearched, 500);
  const sizeResult =
    0 +
    (historyNumber && isResultVisible ? historyNumber * 32 + 30 : 0) +
    (results && results.length > 0 && isResultVisible
      ? containerHeight * results.length
      : 0);

  const handleSearch = (value: string) => {
    setValueSearched(value);
    setHistoryNumber(historyNumber + 1);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleClear = () => {
    setValueSearched('');
    if (onClear) {
      onClear();
    }
  };

  const handleHistoryNumber = (items: string[]) => {
    setHistoryNumber(items.length);
    if (onHistoryChange) {
      onHistoryChange(items);
    }
  };

  return {
    setIsResultVisible,
    isResultVisible,
    valueSearched,
    searchValue,
    sizeResult,
    handleSearch,
    handleClear,
    handleHistoryNumber,
  };
};
