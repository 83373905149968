import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useDifferedValue } from '@savgroup-front-common/core/src/hooks';
import { MessageType, RESULT_TYPE } from '@savgroup-front-common/types';
import { TABS_FILE } from 'control/constants/routing';
import {
  buildFilesUrl,
  buildOrdersUrl,
  buildSparePartRequestLinesUrl,
  buildStockItemUrl,
} from 'control/helpers';
import { ResultSearchType } from 'control/view/molecules/SearchWithDimmer/SearchWithDimmer.types';

import { useFilesPageContext } from '../../pages/FilesPageNewUI/FilesPage.context';

import { MENU_NAMES } from './Menu/Menu.types';
import messages from './messages';
import { searchResults } from './Search/helpers';

export const useHeaderNewBo = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string | undefined>();
  const [searchResult, setSearchResult] = useState<
    | {
        hits?: any[];
        hitCount?: number;
        title: MessageType;
        icon: string;
        iconColor?: { color: string; bgColor: string };
      }[]
    | undefined
  >([]);
  const [showMoreNumber, setShowMoreNumber] = useState(0);
  const debounceSearching = useDifferedValue(query, 250);
  const { push } = useHistory();

  useEffect(() => {
    setSearchResult(undefined);
  }, [query]);

  const handleSearch = useCallback(async () => {
    if (!debounceSearching) {
      setIsLoading(false);

      return setSearchResult(undefined);
    }

    const resultTypes = [
      RESULT_TYPE.FILE,
      RESULT_TYPE.ORDER,
      RESULT_TYPE.SPARE_PART_REQUEST,
      RESULT_TYPE.STOCK_ITEM,
    ];

    const response = await searchResults({
      query: debounceSearching.trim().toUpperCase(),
      page: 1,
      pageSize: 3 + showMoreNumber * 15,
      enabledResultTypes: resultTypes,
      searchPermissions: resultTypes,
    });

    const resultSearched = [];

    if (response.file.hitCount) {
      resultSearched.push({
        hits: response.file.hit?.map((hit) => ({
          searchId: hit.fileId,
          searchReference: hit.fileReference,
          searchLocalizedInfo: hit.localizedSolutionType,
          searchFirstName: hit.ownerFirstName,
          searchLastName: hit.ownerLastName,
          searchUrl: buildFilesUrl({
            fileId: hit.fileId,
            fileTabId: TABS_FILE.SUMMARY,
          }),
          highlights: hit.highlights,
        })),
        hitCount: response.file.hitCount,
        title: messages.files,
        icon: 'Folder',
        iconColor: theme.newUI?.iconColors.primary,
      });
    }

    if (response.order.hitCount) {
      resultSearched.push({
        hits: response.order.hit?.map((hit) => ({
          searchId: hit.orderId,
          searchReference: hit.orderReference,
          searchLocalizedInfo: hit.sellerName,
          searchFirstName: hit.firstName,
          searchLastName: hit.lastName,
          searchUrl: buildOrdersUrl({ orderId: hit.orderId }),
        })),
        hitCount: response.order.hitCount,
        title: messages.order,
        icon: 'Order',
        iconColor: theme.newUI?.iconColors.caution,
      });
    }

    if (response.sparePartRequest.hitCount) {
      resultSearched.push({
        hits: response.sparePartRequest.hit?.map((hit) => ({
          searchId: hit.requestLineId,
          searchReference: hit.sparePartReference,
          searchLocalizedInfo: hit.requestLineStatus,
          searchFirstName: hit.requesterName,
          searchLastName: `${hit.handlingType} ${hit.supplierName}`,
          searchUrl: buildSparePartRequestLinesUrl({
            requestLineId: hit.requestLineId,
          }),
        })),
        hitCount: response.sparePartRequest.hitCount,
        title: messages.sparePartRequest,
        icon: 'Sparepart',
        iconColor: theme.newUI?.iconColors.info,
      });
    }

    if (response.stockItem.hitCount) {
      resultSearched.push({
        hits: response.stockItem.hit?.map((hit) => ({
          searchId: hit.requestLineId,
          searchReference: hit.fileReference,
          searchLocalizedInfo: hit.sparePartReference,
          searchFirstName: hit.stockName,
          searchLastName: hit.supplierName,
          searchUrl: buildStockItemUrl({
            stockItemId: hit.stockItemId,
          }),
        })),
        hitCount: response.stockItem.hitCount,
        title: messages.stockItem,
        icon: 'Stock',
        iconColor: theme.newUI?.iconColors.success,
      });
    }

    setSearchResult(resultSearched);
    setIsLoading(false);

    return resultSearched;
  }, [
    debounceSearching,
    showMoreNumber,
    theme.newUI?.iconColors.caution,
    theme.newUI?.iconColors.info,
    theme.newUI?.iconColors.primary,
    theme.newUI?.iconColors.success,
  ]);

  useEffect(() => {
    handleSearch();
  }, [debounceSearching, handleSearch]);

  const handleShowMore = () => {
    setShowMoreNumber(showMoreNumber + 1);
  };
  const { handleAddTab } = useFilesPageContext();

  const handleResultClick = (hit: ResultSearchType) => {
    if (!hit.searchUrl) {
      return;
    }
    push(hit.searchUrl);
    handleAddTab({
      url: hit.searchUrl,
      title: hit.searchReference,
      type: hit.searchUrl.includes('orders')
        ? MENU_NAMES.ORDERS
        : MENU_NAMES.FILES,
    });
  };

  return {
    searchResult,
    isLoading,
    query,
    setQuery,
    setIsLoading,
    setShowMoreNumber,
    handleShowMore,
    handleResultClick,
  };
};
