import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  FEATURE_FLAGS_FOR_SELLER,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}features`;

interface GetFeatureFlagsQueryResponseValue {
  key: FEATURE_FLAGS_FOR_SELLER;
  value: boolean;
}

const getFeatureFlagsQuery = async ({
  sellerId,
}: {
  sellerId?: string;
}): Promise<
  BackResponse<GetFeatureFlagsQueryResponseValue[]> | BackResponseFailure
> => {
  try {
    const response = await apiCall<
      BackResponse<GetFeatureFlagsQueryResponseValue[]>
    >(buildUrl(ENDPOINT, { sellerId }), {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getFeatureFlagsQuery;
