import {
  FileIcon,
  TaskIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { ROUTES } from '../../../constants/routing';
import { buildFilesUrl, buildOrdersUrl, buildTodosUrl } from '../../../helpers';
import { MENU_NAMES } from '../../components/Header/Menu/Menu.types';

import messages from './messages';

export const MENU_CONF = {
  [MENU_NAMES.TODOS]: {
    path: ROUTES.TODOS,
    url: buildTodosUrl(),
    icon: TaskIcon,
    message: messages.todos,
    isDraggable: false,
    isRemovable: false,
  },
  [MENU_NAMES.FILES]: {
    path: ROUTES.FILES,
    url: buildFilesUrl(),
    icon: FileIcon,
    message: messages.files,
    isDraggable: false,
    isRemovable: false,
  },
  [MENU_NAMES.ORDERS]: {
    path: ROUTES.ORDERS,
    url: buildOrdersUrl(),
    icon: FileIcon,
    message: messages.orders,
    isDraggable: false,
    isRemovable: false,
  },
};
