import { useEffect, useState } from 'react';

export const useDifferedValue = (value?: any, delay?: number) => {
  const [differedValue, setDifferedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDifferedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return differedValue;
};
