import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { decelerateTimingFunctionAnimation } from '@savgroup-front-common/core/src/animations/timingFunction';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  rem,
  styledGetCustomComponentProperty,
} from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { SearchInputVariant } from './SearchInput.types';

export const $SearchIconContainer = styled.div<{ $isNewUiEnabled: boolean }>`
  position: absolute;
  left: 0.75rem;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};
  display: flex;
  align-items: center;
`;
export const $SearchLoadingContainer = styled.div`
  position: absolute;
  top: ${rem(10)};
  left: ${rem(2)};
  background: #eee;
  border-radius: 50%;
  width: ${rem(15)};
  height: ${rem(15)};
`;

export const $SearchInputContainer = styled.div<{
  $variant: SearchInputVariant;
  $isNewUiEnabled: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};

  svg {
    color: ${({ $variant, theme, $isNewUiEnabled }) => {
      if ($isNewUiEnabled) {
        return 'rgba(255, 255, 255, 0.7)';
      }

      return $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.colors.white
        : null;
    }};
  }

  &:hover {
    svg {
      color: ${({ $variant, theme }) =>
        $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
          ? theme.colors.mainTextColor
          : null};
    }
  }
`;

export const $SearchClearButton = styled(Button)`
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  ${decelerateTimingFunctionAnimation};
  &:focus {
    opacity: 1;
    pointer-events: all;
  }
`;
export const $SearchActionContainer = styled.div<{ $isNewUiEnabled: boolean }>`
  position: absolute;
  right: 0;

  display: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? 'none' : 'flex')};
  flex-direction: row;
  width: fit-content;
  button {
    max-width: ${styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    })};
  }
`;

const defaultVariant = css<{ $variant: SearchInputVariant }>`
  background: ${({ $variant, theme }) =>
    $variant === SearchInputVariant.DEFAULT ? theme.colors.white : null};
  border-color: ${({ $variant, theme }) =>
    $variant === SearchInputVariant.DEFAULT ? theme.colors.default : null};

  &:focus {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT ? theme.colors.primary : null};
  }
  &:hover {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT ? theme.colors.primary : null};
    background: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT ? theme.colors.white : null};
    box-shadow: ${({ theme }) =>
      `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`};
  }
`;

const globalSearchVariant = css<{ $variant: SearchInputVariant }>`
  background: ${({ $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH ? '#eee' : null};
  border-color: ${({ $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH ? '#eee' : null};

  &:focus {
    border-color: ${({ $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH ? '#999 !important' : null};
  }
  &:hover {
    border-color: ${({ $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH ? '#e2e2e2' : null};
    background: ${({ $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH ? '#e2e2e2' : null};
  }
`;

const globalSearchNewUIVariant = css<{ $variant: SearchInputVariant }>`
  background: ${({ $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
      ? 'rgba(255, 255, 255, .15)'
      : null};
  border-color: ${({ $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
      ? 'transparent'
      : null};
  color: ${({ $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI ? 'white' : null};

  &::placeholder {
    color: ${({ $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? rgba(255, 255, 255, 0.7)
        : null};
  }

  &:focus {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.newUI?.defaultColors?.secondary
        : null};
    box-shadow: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? `0px 0px 6px 0px ${theme.newUI?.defaultColors?.primary}`
        : null};
  }
  &:hover {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.newUI?.defaultColors?.secondary
        : null};
    color: ${({ theme, $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.colors.white
        : null};
    &::placeholder {
      color: ${({ theme, $variant }) =>
        $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
          ? theme.colors.mainTextColor
          : null};
    }
  }
`;

export const $SearchInput = styled.input<{
  $variant: SearchInputVariant;
  $isNewUiEnabled: boolean;
}>`
  border: 0;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};
  &:not([value='']) {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
  &[value=''] {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      pointer-events: none;
    }
  }

  ${defaultVariant};
  ${globalSearchVariant};
  ${globalSearchNewUIVariant};

  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0 0.5rem 0 3rem;
  flex: 1;

  transition-duration: 0.3s;
  border-width: 1px;
  border-style: solid;

  ${decelerateTimingFunctionAnimation};

  &:hover,
  &:focus {
    outline: 0;
  }

  @media ${media.maxWidth.xs} {
    padding: 0 2.5rem 0 3rem;
  }
`;
