import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';

import { PERMISSIONS } from '@savgroup-front-common/constants';
import { getFromLocalStorage } from '@savgroup-front-common/core/src/helpers';
import {
  useHasPermission,
  useHasSomePermissions,
} from '@savgroup-front-common/core/src/hooks';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';
import { SearchService } from 'control/api';

import { selectTodosListCount } from '../../../domains/todosList/selectors';
import { MENU_NAMES } from '../../components/Header/Menu/Menu.types';
import { MenuItem } from '../../components/Tabs/Tabs.types';

import { MENU_CONF } from './FilesPage.constants';

export const useFilesPage = ({ pathname }: { pathname: string }) => {
  const hasFilesPermission = useHasPermission(PERMISSIONS.SEARCH_FILE);
  const hasTodoAndAlertPermissions = useHasSomePermissions([
    PERMISSIONS.GET_WORKFLOW,
    PERMISSIONS.WORKFLOW_MANAGEMENT,
  ]);
  const todosCount = useSelector(selectTodosListCount);
  const hasGetOrdersPermission = useHasPermission(PERMISSIONS.GET_ORDER);
  const filters = getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.FILES_LIST_FILTERS,
  }) as any;

  const { isLoading, data: defaultTabs } = useQuery(
    ['useFilesList', { filters }],
    async () => {
      const params = {
        filters: filters?.selectedFilters || [],
        isDescending: true,
        page: 1,
        pageSize: 1,
        sort: 'LastStateModification',
        query: '',
      };
      const response = await SearchService.filterFilesCommand(params);

      const initTabs = [
        hasFilesPermission
          ? {
              isActive: !!matchPath(pathname, {
                path: MENU_CONF[MENU_NAMES.FILES].url,
                exact: true,
              }),
              isRemovable: MENU_CONF[MENU_NAMES.FILES].isRemovable,
              isDraggable: MENU_CONF[MENU_NAMES.FILES].isDraggable,
              to: MENU_CONF[MENU_NAMES.FILES].url,
              count: !response.failure ? response.value.hitCount : 0,
              icon: MENU_CONF[MENU_NAMES.FILES].icon,
              message: MENU_CONF[MENU_NAMES.FILES].message,
              dataTestId: MENU_NAMES.FILES,
              type: MENU_NAMES.FILES,
            }
          : undefined,
        hasTodoAndAlertPermissions
          ? {
              isActive: !!matchPath(pathname, {
                path: MENU_CONF[MENU_NAMES.TODOS].url,
              }),
              isRemovable: MENU_CONF[MENU_NAMES.TODOS].isRemovable,
              isDraggable: MENU_CONF[MENU_NAMES.TODOS].isDraggable,
              to: MENU_CONF[MENU_NAMES.TODOS].url,
              count: todosCount,
              icon: MENU_CONF[MENU_NAMES.TODOS].icon,
              message: MENU_CONF[MENU_NAMES.TODOS].message,
              dataTestId: MENU_NAMES.TODOS,
              type: MENU_NAMES.TODOS,
            }
          : undefined,
        hasGetOrdersPermission
          ? {
              isActive: !!matchPath(pathname, {
                path: MENU_CONF[MENU_NAMES.ORDERS].url,
              }),
              isRemovable: MENU_CONF[MENU_NAMES.ORDERS].isRemovable,
              isDraggable: MENU_CONF[MENU_NAMES.ORDERS].isDraggable,
              to: MENU_CONF[MENU_NAMES.ORDERS].url,
              count: 0,
              icon: MENU_CONF[MENU_NAMES.ORDERS].icon,
              message: MENU_CONF[MENU_NAMES.ORDERS].message,
              dataTestId: MENU_NAMES.ORDERS,
              type: MENU_NAMES.ORDERS,
            }
          : undefined,
      ].filter((menu) => menu) as MenuItem[];

      const oldTodoCount = initTabs?.find(
        (menu) => menu.type === MENU_NAMES.TODOS,
      )?.count;

      if (oldTodoCount !== todosCount) {
        return initTabs?.map((menu) =>
          menu.type === MENU_NAMES.TODOS
            ? { ...menu, count: todosCount }
            : menu,
        );
      }

      return initTabs;
    },
    { staleTime: Infinity },
  );

  return {
    defaultTabs,
    isLoading,
  };
};
