export const PaletteProvider = (
  palette: any,
  create?: any,
  elementFactory?: any,
  spaceTool?: any,
  lassoTool?: any,
  handTool?: any,
) => {
  palette.registerProvider(this);

  const getPaletteEntries = () => {
    const createAction = (
      type: string,
      group: string,
      className: string,
      title?: string,
    ) => {
      function createListener(event: any) {
        const shape = elementFactory.createShape({ type });

        create.start(event, shape);
      }

      const shortType = type.replace(/^bpmn:/, '');

      return {
        group,
        className,
        title: title || `Create ${shortType}`,
        action: {
          dragstart: createListener,
          click: createListener,
        },
      };
    };

    const actions = {
      'hand-tool': {
        group: 'tools',
        className: 'bpmn-icon-hand-tool',
        title: 'Activate the create/remove space tool',
        action: {
          click(event: any) {
            handTool.activateSelection(event);
          },
        },
      },
      'lasso-tool': {
        group: 'tools',
        className: 'bpmn-icon-lasso-tool',
        title: 'Activate the lasso tool',
        action: {
          click(event: any) {
            lassoTool.activateSelection(event);
          },
        },
      },

      'create.start-event': createAction(
        'bpmn:StartEvent',
        'event',
        'bpmn-icon-start-event-none',
      ),

      'create.action-task': createAction(
        'bpmn:ActionTask',
        'activity',
        'icon-custom-circle',
      ),
      'create.question-task': createAction(
        'bpmn:QuestionTask',
        'activity',
        'icon-custom-triangle',
      ),
      'create.task': createAction('bpmn:Task', 'activity', 'bpmn-icon-task'),
    };

    return actions;
  };

  return {
    getPaletteEntries,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
  };
};
