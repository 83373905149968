import React, { FC, ReactElement } from 'react';

import { POSITIONS } from '@savgroup-front-common/constants';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { MessageType } from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import { SEARCH_ANIMATION_DURATION } from 'control/view/components/Header/Search/Search.contants';

import SearchHistory from './SearchHistory/SearchHistory';
import SearchInput from './SearchInput/SearchInput';
import SearchResult from './SearchResult/SearchResult';
import { useSearchWithDimmer } from './SearchWithDimmer.hooks';
import {
  $ResultContainer,
  $SearchWithDimmerWrapper,
} from './SearchWithDimmer.styles';
import { ResultSearchType } from './SearchWithDimmer.types';

const SearchWithDimmer: FC<{
  query?: string;
  placeholder?: MessageType | string;
  containerHeight?: number;
  results?: SearchResultList<ResultSearchType>[];
  isLoading?: boolean;
  onSearch?: (value: string) => void;
  onResultClick: (hit: ResultSearchType) => void;
  onShowMore?: () => void;
  onHistoryChange?: (items: string[]) => void;
  historySearched?: string[];
  variant?: string;
  minWidthResult?: number;
  position?: POSITIONS;
  topResult?: number;
  searchFilter?: ReactElement;
  withoutMarginBottom?: boolean;
}> = ({
  query = '',
  placeholder,
  containerHeight = 290,
  results,
  isLoading = false,
  onSearch,
  onResultClick,
  onShowMore,
  onHistoryChange,
  historySearched,
  variant,
  minWidthResult,
  position = POSITIONS.LEFT,
  topResult,
  searchFilter,
  withoutMarginBottom = false,
}) => {
  const {
    searchValue,
    valueSearched,
    isResultVisible,
    sizeResult,
    handleHistoryNumber,
    handleSearch,
    setIsResultVisible,
  } = useSearchWithDimmer({
    query,
    results,
    containerHeight,
    onHistoryChange,
    onSearch,
  });

  return (
    <$SearchWithDimmerWrapper>
      <FormGroup withoutPaddingBottom={withoutMarginBottom}>
        <SearchInput
          value={valueSearched}
          placeholder={placeholder}
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={() => setIsResultVisible(true)}
          isActive={isResultVisible}
          isLoading={isLoading}
          variant={variant}
          searchFilter={searchFilter}
        />
      </FormGroup>

      <$ResultContainer
        $isVisible={isResultVisible}
        $size={sizeResult}
        $minWidth={minWidthResult}
        $position={position}
        $top={topResult}
        animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
      >
        <SearchHistory
          historySearched={historySearched}
          searchItem={searchValue}
          onHistorySet={handleHistoryNumber}
          onClick={(value) => handleSearch(value)}
        />

        {results && results.length > 0 && (
          <SearchResult
            results={results}
            searchValue={valueSearched}
            onClick={onResultClick}
            onShowMore={onShowMore}
          />
        )}
      </$ResultContainer>

      <BackgroundDimmer
        onClick={() => setIsResultVisible(false)}
        isOpen={isResultVisible}
        animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
        zIndex={11}
        opacity={0.25}
      />
    </$SearchWithDimmerWrapper>
  );
};

SearchWithDimmer.displayName = 'SearchWithDimmer';
export default SearchWithDimmer;
