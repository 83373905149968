/* eslint-disable max-len */
import { GitCompare } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const RebaseIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '16px',
}) => <GitCompare width={size} height={size} color={color} />;

RebaseIcon.displayName = 'RebaseIcon';

export default RebaseIcon;
