import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}video/session/history`;

export interface VideoCallSummaryDto {
  callerName: string;
  callDate: string;
  callEndDate?: string;
  isInProgress: boolean;
}

interface GetVideoSessionHistoryQueryPayload {
  fileId: string;
}

export const getVideoSessionHistoryQuery = async ({
  fileId,
}: GetVideoSessionHistoryQueryPayload): Promise<
  BackResponse<VideoCallSummaryDto[]> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<VideoCallSummaryDto[]>>(
      buildUrl(ENDPOINT, { fileId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
