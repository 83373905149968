import { generatePath } from 'react-router-dom';

import { StockItemId } from '@savgroup-front-common/types';

import {
  FILES_BASES,
  MASTER_ROUTES,
  ROUTES,
  SETTINGS_ROUTES_WITH_PARAMS,
  SETTINGS_SELLERS_PATH,
  SPARE_PART_ROUTES,
  TABS_BILLING,
  TABS_FILE,
  TABS_FILE_HISTORIC,
  TABS_HISTORIES,
  TABS_MASTER,
  TABS_SELLERS,
  TABS_SELLERS_TYPE,
  TABS_SETTINGS,
  TABS_SPARE_PARTS,
  TABS_TENANTS,
  TABS_TESTS,
} from '../constants/routing';
import { TABS_CONFIGURATION_CONSOLE } from '../view/pages/ConfigurationConsole/ConfigurationConsoleContent.constants';

export function buildFilesUrl({
  fileId,
  fileTabId,
}: {
  fileId?: string;
  fileTabId?: TABS_FILE;
} = {}): string {
  return generatePath(ROUTES.ALL_FILES, {
    fileId,
    fileTabId,
    fileBase: FILES_BASES.FILES,
  });
}

export function buildHistoricFilesUrl({
  fileId,
  fileTabId,
}: {
  fileId?: string;
  fileTabId?: TABS_FILE_HISTORIC;
} = {}): string {
  return generatePath(ROUTES.ALL_FILES, {
    fileId,
    fileTabId,
    fileBase: FILES_BASES.FILES_HISTORIC,
  });
}

export function buildTodosUrl({
  fileId,
  fileTabId,
}: {
  fileId?: string;
  fileTabId?: TABS_FILE;
} = {}): string {
  return generatePath(ROUTES.ALL_FILES, {
    fileId,
    fileTabId,
    fileBase: FILES_BASES.TODOS,
  });
}

export function buildAlertsUrl({
  fileId,
  fileTabId,
}: {
  fileId?: string;
  fileTabId?: TABS_FILE;
} = {}): string {
  return generatePath(ROUTES.ALL_FILES, {
    fileId,
    fileTabId,
    fileBase: FILES_BASES.ALERTS,
  });
}

export function buildMessagesUrl({
  ticketId,
}: { ticketId?: string } = {}): string {
  return generatePath(ROUTES.MESSAGES, {
    ticketId,
  });
}

export function buildOrdersUrl({ orderId }: { orderId?: string } = {}): string {
  return generatePath(ROUTES.ORDERS, {
    orderId,
  });
}

export function buildSparePartUrl({
  sparePartMenuId,
}: { sparePartMenuId?: TABS_SPARE_PARTS } = {}): string {
  return generatePath(ROUTES.SPARE_PARTS, {
    sparePartMenuId,
  });
}
export function buildSparePartRequestLinesUrl({
  requestLineId,
}: { requestLineId?: string } = {}): string {
  return generatePath(SPARE_PART_ROUTES.REQUESTS, {
    requestLineId,
  });
}

export function buildStockItemUrl({
  stockItemId,
}: { stockItemId?: StockItemId } = {}): string {
  return generatePath(SPARE_PART_ROUTES.STOCKS, {
    stockItemId,
  });
}
export function buildSasValidationUrl({
  fileReference,
}: { fileReference?: string } = {}): string {
  return generatePath(SPARE_PART_ROUTES.REQUESTS_TO_VALIDATE, {
    fileReference,
  });
}
export function buildBusinessIntelligenceUrl(): string {
  return generatePath(ROUTES.BUSINESS_INTELLIGENCE);
}

type BuildSettingSellersUrlArgs =
  | {
      sellerId?: string;
      sellerType?: TABS_SELLERS_TYPE.SELLER;
      sellerTabId?: TABS_SELLERS;
    }
  | {
      sellerId?: string;
      sellerType?: TABS_SELLERS_TYPE.TENANT;
      sellerTabId?: TABS_TENANTS;
    };
export function buildSettingSellersUrl({
  sellerId,
  sellerType,
  sellerTabId,
}: BuildSettingSellersUrlArgs = {}): string {
  if (sellerId && sellerType === TABS_SELLERS_TYPE.TENANT) {
    return generatePath(SETTINGS_SELLERS_PATH.TENANT, {
      sellerId,
      sellerType,
      sellerTabId,
    });
  }
  if (sellerId && sellerType === TABS_SELLERS_TYPE.SELLER) {
    return generatePath(SETTINGS_SELLERS_PATH.SELLER, {
      sellerId,
      sellerType,
      sellerTabId,
    });
  }

  return generatePath(SETTINGS_ROUTES_WITH_PARAMS.SELLERS, {
    sellerId,
  });
}

type BuildSettingsUrlArgs =
  | {
      settingTabId: TABS_SETTINGS.SELLERS;
      sellerId?: string;
      sellerType?: TABS_SELLERS_TYPE.SELLER;
      sellerTabId?: TABS_SELLERS;
    }
  | {
      settingTabId: TABS_SETTINGS.SELLERS;
      sellerId?: string;
      sellerType?: TABS_SELLERS_TYPE.TENANT;
      sellerTabId?: TABS_TENANTS;
    }
  | {
      settingTabId: TABS_SETTINGS.USERS;
      userId?: string;
    }
  | {
      settingTabId: TABS_SETTINGS.TESTS;
      testTabId?: TABS_TESTS;
    }
  | {
      settingTabId: TABS_SETTINGS.IMPORTS;
    }
  | {
      settingTabId: TABS_SETTINGS.MASTER;
    }
  | {
      settingTabId: TABS_SETTINGS.SPARE_PARTS;
    }
  | void;

export function buildSettingsUrl(payload?: BuildSettingsUrlArgs): string {
  if (!payload) {
    return generatePath(ROUTES.SETTINGS);
  }
  switch (payload.settingTabId) {
    case TABS_SETTINGS.SELLERS: {
      return buildSettingSellersUrl(payload);
    }
    case TABS_SETTINGS.USERS: {
      return generatePath(SETTINGS_ROUTES_WITH_PARAMS.USERS, {
        userId: payload.userId,
      });
    }

    case TABS_SETTINGS.TESTS: {
      return generatePath(SETTINGS_ROUTES_WITH_PARAMS.TESTS, {
        testTabId:
          payload.testTabId === TABS_TESTS.SUMMARY
            ? undefined
            : payload.testTabId,
      });
    }
    case TABS_SETTINGS.IMPORTS: {
      return generatePath(SETTINGS_ROUTES_WITH_PARAMS.IMPORTS);
    }
    case TABS_SETTINGS.MASTER: {
      return generatePath(SETTINGS_ROUTES_WITH_PARAMS.MASTER);
    }

    case TABS_SETTINGS.SPARE_PARTS: {
      return generatePath(SETTINGS_ROUTES_WITH_PARAMS.SPARE_PARTS);
    }

    default:
      return generatePath(ROUTES.SETTINGS);
  }
}
export function buildSettingTestsUrl({
  testTabId,
}: {
  testTabId?: TABS_TESTS;
} = {}): string {
  return buildSettingsUrl({
    settingTabId: TABS_SETTINGS.TESTS,
    testTabId,
  });
}

export function buildSettingMasterUrl({
  tabId,
}: {
  tabId?: TABS_MASTER;
} = {}): string {
  if (!tabId) {
    return generatePath(MASTER_ROUTES[TABS_MASTER.BUSINESS_RULES]);
  }

  return generatePath(MASTER_ROUTES[tabId]);
}

interface BuildHistoriesUrl {
  tabId?: TABS_HISTORIES;
  fileUrl?: string;
}

export function buildHistoriesUrl({
  tabId,
  fileUrl,
}: BuildHistoriesUrl): string {
  if (!tabId) {
    return generatePath(`${fileUrl}/${TABS_HISTORIES.FILE}`);
  }

  return generatePath(`${fileUrl}/${tabId}`);
}

export function buildHelpUrl(): string {
  return generatePath(ROUTES.HELP);
}

export function buildBulkChangesUrl(): string {
  return generatePath(ROUTES.BULK_CHANGES);
}

export function buildConfigurationConsoleUrl({
  sellerId,
  consoleTabId,
}: {
  sellerId?: string;
  consoleTabId?: TABS_CONFIGURATION_CONSOLE;
} = {}): string {
  return generatePath(ROUTES.CONFIGURATION_CONSOLE, {
    sellerId: sellerId || 'undefined',
    consoleTabId,
  });
}

export const buildBillingUrl = ({
  sellerId,
  billingTabId,
}: {
  sellerId?: string;
  billingTabId?: TABS_BILLING;
}): string => {
  return generatePath(ROUTES.BILLING, {
    sellerId: sellerId || 'undefined',
    billingTabId,
  });
};
