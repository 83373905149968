import React, { FunctionComponent } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import {
  FEATURE_FLAGS_FOR_SELLER,
  POSITIONS,
  ROLES,
} from '@savgroup-front-common/constants';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { useHasRole } from '@savgroup-front-common/core/src/hooks';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import { ROUTES } from 'control/constants/routing';
import SearchWithDimmer from 'control/view/molecules/SearchWithDimmer/SearchWithDimmer';
import { ResultSearchType } from 'control/view/molecules/SearchWithDimmer/SearchWithDimmer.types';

import { useGetFeatureFlagsQuery } from '../../../hooks';

import CreateButton from './CreateButton';
import GoToSelfcareButton from './GoToSelfcareButton/GoToSelfcareButton';
import { useHeaderNewBo } from './Header.hooks';
import {
  $AppHeaderContainer,
  $HeaderLogo,
  $HeaderPortal,
  $HeaderSearchBar,
  $NavigationMenuWrapper,
  $SellerSelectPortal,
} from './Header.styles';
import Logo from './Logo';
import Menu from './Menu';
import messages from './messages';
import Profile from './Profile';
import Search from './Search';
import { SwitchNewBo } from './SwitchNewBo/SwitchNewBo';

interface HeaderProps {
  onLogoClick: () => void;
  isMenuOpen: boolean;
}

const Header: FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({
  onLogoClick,
  isMenuOpen,
}) => {
  const location: any = useLocation();
  const pathname = location.pathname || location?.location?.pathname;

  const isConfigurationConsolePage = !!matchPath(pathname, {
    path: ROUTES.CONFIGURATION_CONSOLE,
    exact: false,
    strict: false,
  });

  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const { isNewUiEnabled } = useNewUiContext();
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);

  const {
    isLoading,
    searchResult,
    query,
    setQuery,
    setIsLoading,
    setShowMoreNumber,
    handleShowMore,
    handleResultClick,
  } = useHeaderNewBo();

  if (isNewUiEnabled) {
    return (
      <$AppHeaderContainer $isNewUiEnabled>
        <$HeaderLogo>
          <Link to={ROUTES.HOME}>
            <ReversioIcon color="#fff" />
          </Link>
        </$HeaderLogo>

        <$NavigationMenuWrapper>
          <Menu />
        </$NavigationMenuWrapper>

        {!isConfigurationConsolePage && (
          <>
            {canSwitchToNewBo && <SwitchNewBo />}
            {isDemoAdmin && <GoToSelfcareButton />}
            <CreateButton />

            <$HeaderSearchBar>
              <SearchWithDimmer
                isLoading={isLoading}
                query={query}
                placeholder={messages.searchPlaceholder}
                results={
                  (searchResult as SearchResultList<ResultSearchType>[]) || []
                }
                onSearch={(value) => {
                  setIsLoading(true);
                  setShowMoreNumber(0);
                  setQuery(value);
                }}
                onResultClick={handleResultClick}
                onShowMore={handleShowMore}
                variant="transparent"
                minWidthResult={488}
                position={POSITIONS.RIGHT}
                topResult={48}
                withoutMarginBottom
              />
            </$HeaderSearchBar>
          </>
        )}

        {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
        {isConfigurationConsolePage && (
          <$SellerSelectPortal id="headerSellerSelectPortalId" />
        )}
        <Profile />
      </$AppHeaderContainer>
    );
  }

  return (
    <$AppHeaderContainer $isNewUiEnabled={false}>
      <Logo onClick={onLogoClick} isMenuOpen={isMenuOpen} />

      {!isConfigurationConsolePage && (
        <>
          <CreateButton />

          <Search />
        </>
      )}
      {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
      {isConfigurationConsolePage && (
        <$SellerSelectPortal id="headerSellerSelectPortalId" />
      )}

      {canSwitchToNewBo && <SwitchNewBo />}
      <Profile />
    </$AppHeaderContainer>
  );
};

Header.displayName = 'Header';

export default Header;
