import React, { FC } from 'react';

import { createGenericContext } from '@savgroup-front-common/core/src/helpers';

import { useVideoCallLauncher } from './VideoCallLauncher.hooks';

export interface VideoCallLauncherContextArgs {
  applicationId?: string;
  sessionId?: string;
  token?: string;
  shouldDisplayVideoCall: boolean;
  clientShareLink?: string;
  isLoading: boolean;
  onExitCall: () => void;
  onSessionEnd: () => void;
  isSessionEndLoading: boolean;
  onCreateSession: ({
    phone,
    fileId,
    ownerId,
  }: {
    phone: string;
    fileId: string;
    ownerId: string;
  }) => void;
  ownerId?: string;
  fileId?: string;
}

const [useVideoCallLauncherContext, VideoCallLauncherProvider] =
  createGenericContext<VideoCallLauncherContextArgs>();

const VideoCallLauncherContextProvider: FC<React.PropsWithChildren<any>> =
  React.memo(({ children }) => {
    const {
      clientShareLink,
      applicationId,
      sessionId,
      token,
      isLoading,
      handleCreateSession,
      shouldDisplayVideoCall,
      handleExitCall,
      handleSessionEnd,
      isSessionEndLoading,
      ownerId,
      fileId,
    } = useVideoCallLauncher();

    return (
      <VideoCallLauncherProvider
        value={{
          clientShareLink,
          applicationId,
          sessionId,
          token,
          isLoading,
          onCreateSession: handleCreateSession,
          shouldDisplayVideoCall,
          onExitCall: handleExitCall,
          onSessionEnd: handleSessionEnd,
          isSessionEndLoading,
          ownerId,
          fileId,
        }}
      >
        {children}
      </VideoCallLauncherProvider>
    );
  });

VideoCallLauncherContextProvider.displayName =
  'VideoCallLauncherContextProvider';

export { VideoCallLauncherContextProvider, useVideoCallLauncherContext };
