import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}features/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/${COMMON_QUERY_PARAMS.ENTITY_ID}`;

interface UpsertFeatureFlagsCommandPayload {
  entityId: string;
  entityType?: string;
  featureFlags: {
    key: string;
    value: boolean;
  }[];
}

const upsertFeatureFlagsCommand = async ({
  entityType = ACTOR_TYPES.SELLER,
  entityId,
  featureFlags,
}: UpsertFeatureFlagsCommandPayload): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ENTITY_TYPE,
      entityType,
    ).replace(COMMON_QUERY_PARAMS.ENTITY_ID, entityId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { featureFlags },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default upsertFeatureFlagsCommand;
