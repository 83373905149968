import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}/diagnostic/tree/configuration`;

const deleteDiagnosticTreeConfigurationCommand = async ({
  sellerId,
  diagnosticTreeUniqueName,
}: {
  sellerId: string;
  diagnosticTreeUniqueName: string;
}): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.DELETE,
      json: {
        uniqueName: diagnosticTreeUniqueName,
        sellerId,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteDiagnosticTreeConfigurationCommand;
