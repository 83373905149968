import { useState } from 'react';

export const useSearchInput = ({
  onClear,
  onChange,
}: {
  onClear?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const [valueSearched, setValueSearched] = useState<string>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearched(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleClear = () => {
    setValueSearched('');
    if (onClear) {
      onClear();
    }
  };

  return {
    valueSearched,
    handleChange,
    handleClear,
  };
};
