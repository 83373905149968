import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';

import {
  selectSellerById,
  selectSellers,
} from '@savgroup-front-common/core/src/domains/sellers/selectors';
import { createGenericContext } from '@savgroup-front-common/core/src/helpers';
import { LOCAL_STORAGE_KEYS, Seller } from '@savgroup-front-common/types';

import { useControlTypedSelector } from '../../hooks';

export interface AppContextArgs {
  isIframeContext: boolean;
  onIframePageMount: () => void;
  onIframePageUnmount: () => void;
  isNewUiEnabled: boolean;
  onToggleNewBoEnabled: () => void;
  selectedSeller?: Seller;
  onSelectSeller: (sellerId?: string) => void;
}

const [useAppContext, AppProvider] = createGenericContext<AppContextArgs>();

const AppContextProvider: FunctionComponent<
  PropsWithChildren<Record<never, never>>
> = ({ children }) => {
  const [selectedSellerFromStorage, setSelectedSellerFromStorage] =
    useLocalStorage<string>(LOCAL_STORAGE_KEYS.SELECTED_SELLER, undefined);

  const [isIframeContext, setIsIframeContext] = useState(false);
  const [isNewUiEnabledFromStorage, setIsNewUiEnabledFromStorage] =
    useLocalStorage<boolean>(LOCAL_STORAGE_KEYS.NEW_BO_ENABLED, false, {
      raw: false,
      serializer: (value: boolean) => {
        if (value === true) {
          return 'true';
        }

        return 'false';
      },
      deserializer: (value: string) => {
        if (value === 'true') {
          return true;
        }

        return false;
      },
    });
  const [isNewUiEnabled, setIsNewUiEnabled] = useState(
    isNewUiEnabledFromStorage ?? false,
  );

  const onIframePageMount = useCallback(() => {
    setIsIframeContext(true);
  }, []);
  const onIframePageUnmount = useCallback(() => {
    setIsIframeContext(false);
  }, []);

  const onToggleNewBoEnabled = useCallback(() => {
    setIsNewUiEnabledFromStorage(!isNewUiEnabled);
    setIsNewUiEnabled(!isNewUiEnabled);
  }, [isNewUiEnabled, setIsNewUiEnabledFromStorage]);

  const sellers = useControlTypedSelector(selectSellers);

  const firstSeller = sellers[0];
  const isMonoSeller = sellers.length === 1;
  const [selectedSellerId, setSelectedSellerId] = useState(
    selectedSellerFromStorage,
  );

  const onSelectSeller = useCallback(
    (sellerId?: string) => {
      setSelectedSellerId(sellerId);
      setSelectedSellerFromStorage(sellerId);
    },
    [setSelectedSellerFromStorage],
  );

  const selectedSeller = useControlTypedSelector((store) =>
    selectSellerById(store, { sellerId: selectedSellerId }),
  );

  return (
    <AppProvider
      value={{
        isIframeContext,
        onIframePageMount,
        onIframePageUnmount,
        isNewUiEnabled,
        onToggleNewBoEnabled,
        selectedSeller: isMonoSeller ? firstSeller : selectedSeller,
        onSelectSeller,
      }}
    >
      {children}
    </AppProvider>
  );
};

AppContextProvider.displayName = 'AppContextProvider';

export { AppContextProvider, useAppContext };
