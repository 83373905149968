export enum BpmnEvents {
  click = 'element.click',
  create = 'interactionEvents.createHit',
  update = 'interactionEvents.updateHit',
  added = 'shape.added',
  delete = 'shape.removed',
  updateId = 'element.updateId',
  updateProps = 'updateProps',
}

export enum BpmnElementType {
  process = 'bpmn:Process',
  sequenceFlow = 'bpmn:SequenceFlow',
  task = 'bpmn:Task',
  startEvent = 'bpmn:StartEvent',
  label = 'label',
}

export interface BpmnElementNaming {
  element: any;
  id: string;
  uniqueName: string;
  label?: string;
}
