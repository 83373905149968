import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.workflow}files/customerFile/${COMMON_QUERY_PARAMS.FILE_ID}/additionalInformation`;

interface SetFileAdditionalInformationCommandPayload {
  fileId: string;
  additionalInformationValues: {
    id: string;
    value: string | { value: unknown };
  }[];
}

const setFileAdditionalInformationCommand = async ({
  fileId,
  additionalInformationValues,
}: SetFileAdditionalInformationCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        additionalFileInformation: additionalInformationValues,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setFileAdditionalInformationCommand;
