import { Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  ${({ theme }) => theme.shadows.elevation__2};
  background-color: ${({ theme }) => theme.colors.secondaryColor2};
  border-radius: 5px;

  @media ${media.maxWidth.xs} {
    & > div {
      width: 100%;
    }
    & button {
      width: fit-content;
    }
  }
`;

const mainVideoStyle = css`
  height: 100%;
`;
const secondaryVideoStyle = css`
  &&&& {
    position: absolute;
    bottom: 1rem;
    right: 0.5rem;
  }
  &&&& .OT_mute {
    z-index: 99;
  }
`;

export const $Subscriber = styled.div<{
  $isMain: boolean;
  $isMinimised: boolean;
}>`
    display: ${({ $isMain, $isMinimised }) =>
      !$isMain && $isMinimised ? 'none' : 'block'};
  & > div {
      border-radius: 5px;
  }
    
  ${({ $isMain }) => ($isMain ? mainVideoStyle : secondaryVideoStyle)}}
`;
export const $Publisher = styled.div<{
  $isMain: boolean;
  $isMinimised: boolean;
}>`
    display: ${({ $isMain, $isMinimised }) =>
      !$isMain && $isMinimised ? 'none' : 'block'};
    & > div {
        border-radius: 5px;
    }
    
    ${({ $isMain }) => ($isMain ? mainVideoStyle : secondaryVideoStyle)}}
`;

export const $Footer = styled(Row)`
  display: flex;
  justify-content: center;

  position: absolute;
  left: 6px;
  bottom: 0;

  z-index: 50;

  width: 100%;
  height: 4rem;

  background: transparent;

  @media ${media.maxWidth.xs} {
    & > div {
      width: fit-content;
    }
    & button {
      width: fit-content;
    }
  }
`;

export const $SnapshotPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  & img {
    max-width: 70%;
  }
`;
