import {
  FilterCategory,
  OnFacetChangeArgs,
} from '@savgroup-front-common/types';

import createNewFacet from './createNewFacet';

const injectNewFacetInExistingRubric = (
  oldRubrics: FilterCategory[],
  payload: OnFacetChangeArgs,
): FilterCategory[] => {
  const oldRubricIndex = oldRubrics.findIndex(
    (rubric) => rubric.rubricName === payload.rubricName,
  );

  const oldRubric = oldRubrics[oldRubricIndex];

  const newRubrics: FilterCategory[] = [
    ...oldRubrics.slice(0, oldRubricIndex),
    {
      ...oldRubric,
      rubricLabel: payload.rubricName,
      rubricName: payload.rubricName,
      values: [...oldRubric?.values, createNewFacet(payload)],
    },
    ...oldRubrics.slice(oldRubricIndex + 1),
  ];

  return newRubrics;
};

export default injectNewFacetInExistingRubric;
