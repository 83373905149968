import React, { FC } from 'react';
import {
  $SearchHistoryItem,
  $SearchHistoryWrapper,
} from './SearchHistory.styles';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { SearchNewBoIcon } from '@savgroup-front-common/core/src/protons/icons';
import { useSearchHistory } from './SearchHistory.hooks';
import { useTheme } from 'styled-components';

const SearchHistory: FC<{
  historySearched?: string[];
  searchItem?: string;
  onHistorySet: (items: string[]) => void;
  onClick: (searchValue: string) => void;
}> = ({ historySearched = [], searchItem, onHistorySet, onClick }) => {
  const theme = useTheme();
  const { lastItemsSearched } = useSearchHistory({
    historySearched,
    onHistorySet,
    searchItem,
  });

  return lastItemsSearched.length ? (
    <$SearchHistoryWrapper>
      {lastItemsSearched.map((item) => (
        <AnimatedRenderer key={item} isOpen={!!item} delay={250}>
          <$SearchHistoryItem onClick={() => onClick(item)}>
            <SearchNewBoIcon
              color={theme.colors.paragraphTextColor}
              size="16px"
            />
            <>{item}</>
          </$SearchHistoryItem>
        </AnimatedRenderer>
      ))}
    </$SearchHistoryWrapper>
  ) : (
    <></>
  );
};

SearchHistory.displayName = 'SearchHistory';
export default SearchHistory;
