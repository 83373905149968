import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { useCallback, useEffect, useState } from 'react';
import { EMPTY_BPMN } from '../BpmnModeler.constants';

export const useXml = ({
  modeler,
  xml,
  url,
  subscribeToBpmnEvents,
  onUpdate,
}: {
  modeler?: any;
  xml?: string;
  url?: string;
  subscribeToBpmnEvents: () => void;
  onUpdate: ({
    viewer,
    hasDebounce,
  }: {
    viewer: any;
    hasDebounce?: boolean;
  }) => void;
}) => {
  const [isXmlImported, setIsXmlImported] = useState(false);

  const importXML = useCallback(
    async (xml: string) => {
      if (modeler?.getDefinitions() || isXmlImported) {
        return;
      }
      try {
        await modeler?.importXML(xml);

        modeler?.get('canvas').zoom('fit-viewport');
      } catch (err: any) {
        logError(err);
      }
      subscribeToBpmnEvents();
      setIsXmlImported(true);
    },
    [modeler, isXmlImported, subscribeToBpmnEvents],
  );

  const createDiagram = useCallback(async () => {
    await importXML(EMPTY_BPMN);

    setTimeout(() => {
      onUpdate({ viewer: modeler });
    });
  }, [importXML, modeler, onUpdate]);

  const fetchDiagram = useCallback(
    (url: string) => {
      fetch(url, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
        .then((response) => response.text())
        .then((text) => {
          importXML(text);
        });
    },
    [importXML],
  );

  useEffect(() => {
    if (!modeler || modeler?.getDefinitions()) {
      return;
    }
    if (xml) {
      importXML(xml);
    } else if (url) {
      fetchDiagram(url);
    } else {
      createDiagram();
    }
  });
};
