import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BaseBackResponse,
  SparePartCondition,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestsNew`;

export interface SparePartRequestLineNewModelDto {
  id: string;
  quantity: number;
  excludingTaxPurchasePriceEur: number;
  isUncertain: boolean;
  sparePartId?: string;
  externalOrderId?: string;
  externalRequestLineId?: string;
  sparePartSupplierId: string;
  minDeliveryDays?: number;
  maxDeliveryDays?: number;
  stockName?: string;
  condition: SparePartCondition;
}

export interface CartLineReferencingRequestDto {
  id: string;
  isUncertain: boolean;
  subCategory?: string;
  quantity: number;
  manufacturerId?: string;
  manufacturerReference?: string;
  manufacturerName?: string;
  sparePartSupplierId?: string;
  sparePartSupplierReference?: string;
  sparePartSupplierName?: string;

  additionalInformation?: string;
  label?: string;
  additionalFiles?: string[];
}

interface CreateSparePartRequestNewModelCommandPayload {
  fileId?: string;
  sellerId: string;
  requestLines: SparePartRequestLineNewModelDto[];
  sparePartsToReference: CartLineReferencingRequestDto[];
  deliveryLocationType: string;
  address?: AddressInfoDto;
  deliveryLocationActorId?: string;
}

export async function createSparePartRequestNewModelCommand(
  payload: CreateSparePartRequestNewModelCommandPayload,
): Promise<BaseBackResponse> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
