import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-tippy/dist/tippy.css';

import { appShell } from '@savgroup-front-common/core/src/helpers';
import { setStoreAndHistory } from 'control/view/app/App.config';

import '../datadog.config';

import './helpers/localMoment';
import { ControlUserManagerConfig } from './helpers/userManager';
import App from './view/app/App';
import './view/wdyr';

if (![].at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

const { userManager } = ControlUserManagerConfig.cache;

setStoreAndHistory({ userManager });

appShell({
  userManager,
  render: () => {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(<App userManager={userManager} />);
  },
});
