import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockItemGroupInInventorySummaryDto } from './type/StockItemGroupInInventorySummaryDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.sparePart}stockInventory/Close/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;

export interface CloseInventoryCommandArgs {
  stockInventoryId: string;
}

export default async function closeInventoryCommand({
  stockInventoryId,
}: CloseInventoryCommandArgs): Promise<
  BackResponse<StockItemGroupInInventorySummaryDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<StockItemGroupInInventorySummaryDto>
    >(ENDPOINT.replace(COMMON_QUERY_PARAMS.STOCK_ITEM_ID, stockInventoryId), {
      method: SUPPORTED_METHODS.PUT,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
