import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}roles/configurable/${COMMON_QUERY_PARAMS.ENTITY_TYPE}`;

interface RolesForSellerSummaryDto {
  roles: {
    id: string;
    name: string;
    description: string;
  }[];
  sellerName: string;
  sellerId: string;
}

interface GetConfigurableRolesQueryPayload {
  entityId?: string;
  entityType?: string;
}

export const getConfigurableRolesQuery = async ({
  entityId,
  entityType = ACTOR_TYPES.SELLER,
}: GetConfigurableRolesQueryPayload): Promise<
  BackResponse<RolesForSellerSummaryDto[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ENTITY_TYPE, entityType);

    const response = await apiCall<BackResponse<RolesForSellerSummaryDto[]>>(
      buildUrl(url, { entityId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
