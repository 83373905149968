import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}video/session/forcedisconnect`;

interface ForceDisconnectClientsCommandPayload {
  fileId: string;
}

export const forceDisconnectClientsCommand = async ({
  fileId,
}: ForceDisconnectClientsCommandPayload): Promise<
  BackResponse<never> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<never>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { fileId },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
