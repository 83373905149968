import { defineMessages } from 'react-intl';

export default defineMessages({
  productNumber: {
    id: 'view.orders.nav.product',
    defaultMessage:
      '{productCount, plural, one {1 product} other {# products}}',
  },
  files: {
    id: 'view.nav.items.folder',
    defaultMessage: 'Files',
  },
  todos: {
    id: 'view.nav.items.dashboard',
    defaultMessage: 'Dashboard',
  },
  desc: {
    id: 'page.files.orderByDate.desc',
    defaultMessage: 'Order to oldest',
  },
  asc: {
    id: 'page.files.orderByDate.asc',
    defaultMessage: 'Order to newest',
  },
  searchFilesPlaceHolder: {
    id: 'view.search.search_by_file_number',
    defaultMessage: 'Search by file number',
  },
  orders: {
    id: 'view.nav.items.orders',
    defaultMessage: 'Orders',
  },
});
