import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.ENTITY_TYPE}`;

interface UpsertEntityFromConsoleCommandArg {
  sellerId: string;
  entityType: string;
  payload: Record<
    string,
    string | number | boolean | { [key: string]: boolean | number | string }
  >;
}

async function UpsertEntityFromConsoleCommand({
  sellerId,
  entityType,
  payload,
}: UpsertEntityFromConsoleCommandArg): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    const response = await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.ENTITY_TYPE,
        entityType,
      ),
      { method: SUPPORTED_METHODS.POST, json: payload },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default UpsertEntityFromConsoleCommand;
