import { useModeler } from './hooks/useModeler';
import { useEventModeler } from './hooks/useEventModeler';
import { useXml } from './hooks/useXml';

export const useBpmnModeler = ({
  containerRef,
  xml,
  url,
  onClick,
  onCreate,
  onUpdate,
  onDeleteNode,
}: {
  containerRef: React.MutableRefObject<null>;
  xml?: string;
  url?: string;
  onClick: ({ name, id }: { name: string; id: string }) => void;
  onCreate: ({
    element,
    gfx,
    viewer,
  }: {
    element: any;
    gfx: any;
    viewer: any;
  }) => void;
  onUpdate: ({
    viewer,
    hasDebounce,
  }: {
    viewer: any;
    hasDebounce?: boolean;
  }) => void;
  onDeleteNode: ({
    element,
    xml,
    viewer,
  }: {
    element: any;
    xml: string;
    viewer: any;
  }) => void;
}) => {
  const { modeler } = useModeler({ containerRef });
  const { subscribeToBpmnEvents } = useEventModeler({
    modeler,
    onClick,
    onCreate,
    onUpdate,
    onDeleteNode,
  });

  useXml({ modeler, xml, url, subscribeToBpmnEvents, onUpdate });
};
