import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}//safetyrisk`;

interface GetModelSafetyriskFilePayload {
  fileId: string;
}

const getModelSafetyriskFile = async ({
  fileId,
}: GetModelSafetyriskFilePayload): Promise<
  BackResponse<Record<string, string>> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<Record<string, string>>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getModelSafetyriskFile;
